import Axios from '../services/Axios'

export function loginWithUsername(params) {
  return Axios.getInstance().post('/v1/auth/login', {
    params,
  })
}

export function changePassword(params) {
  return Axios.getInstance().put('/v1/my/change-password', {
    ...params,
  })
}
