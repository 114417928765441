import {
  Button,
  Collapse,
  DatePicker,
  Drawer,
  Dropdown,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
  Spin,
  Table,
  Tabs,
} from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { CaretDownFilled, MinusCircleOutlined, SearchOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import ImageArrayUpload from '../../../../components/common/imageArrayUpload'
import {
  deleteSellerApi, detailPartnerApi, editPartnerApi, listSellers,
} from '../../../../apis/partnerApis'
import useDebounce from '../../../../hook/useDebounce'
import { DEFAULT_PAGINATE_SIZE } from '../../../../constants/config'
import ModalDelete from './ModalDelete'
import { COMMISSION_PRODUCT, DEFAULT_IMAGE, GENDER_LIST, regexPhoneNumber } from '../../../../constants/constant'
import ExternalAccountApis from '../../../../apis/externalAccountApis'
import ModalChangeStatusPartner from './ModalChangeStatusPartner'
import ModalResetPassword from './ModalResetPassword'
import DetailSeller from '../partnerView/DetailSeller'
import UploadImageComponent from '../../../../components/common/uploadImage'
import { isHasPermission } from '../../../../redux/reducers/permission'
import { PERMISSION } from '../../../../constants/permission'

const getTextTag = {
  suspend: 'Tạm ngừng',
  active: 'Hoạt động',
  pending: 'Chờ duyệt',
  rejected: 'Từ chối',
}

function DetailPartner(props) {
  const {
    isOpen,
    setIsOpen,
    selectedRepresentative,
    getListPartner,
    isProfile,
  } = props

  const [loadingDetail, setLoadingDetail] = useState(false) // Loading khi lấy chi tiết tài khoản partner
  const [detailAccount, setDetailAccount] = useState({}) // Chi tiết tài khoản partner

  const [isEdit, setIsEdit] = useState(false) // Trạng thái chỉnh sửa
  const [loadingEdit, setLoadingEdit] = useState(false) // Loading khi chỉnh sửa

  const [activeKey, setActiveKey] = React.useState(['1', '2', '3', '4']) // active collapse
  const [activeTab, setActiveTab] = React.useState('1') // active tab
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]) // state checkbox table
  const [selectedFilterDropdown, setSelectedFilterDropdown] = useState([
    'active',
    'suspend',
  ]) // state filter dropdown

  const [sellerList, setSellerList] = React.useState([]) // Danh sách tài khoản cấp dưới
  const [tableLoading, setTableLoading] = React.useState(false) // Loading danh sách tài khoản cấp dưới

  const [keywordInput, setKeywordInput] = React.useState('') // Từ khóa tìm kiếm
  const [page, setPage] = React.useState(1) // Trang hiện tại
  const [total, setTotal] = React.useState(0) // Số lượng tài khoản cấp dưới

  const [storeImage, setStoreImage] = useState([]) // Ảnh cửa hàng
  const [avatarUrl, setAvatarUrl] = useState([]) // Ảnh đại diện

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false) // Modal xóa tài khoản cấp dưới
  const [isOpenModalChangeStatus, setIsOpenModalChangeStatus] = useState(false) // Modal thay đổi trạng thái tài khoản partner
  const [isOpenModalResetPassword, setIsOpenModalResetPassword] = useState(false) // Modal thiết lập lại mật khẩu tài khoản partner

  const [detailForm] = Form.useForm()

  const keywordSearch = useDebounce(keywordInput, 300)

  const [listProvince, setListProvince] = useState([]) // danh sách tỉnh/thành phố
  const [listDistrict, setListDistrict] = useState([]) // danh sách quận/huyện
  const [listWard, setListWard] = useState([]) // danh sách phường/xã

  const [provinceID, setProvinceID] = useState(null) // Tỉnh/thành phố được chọn
  const [districtID, setDistrictID] = useState(null) // Quận/huyện được chọn
  const [wardID, setWardID] = useState(null) // Phường/xã được chọn

  const [isOpenDetailSeller, setIsOpenDetailSeller] = useState(false) // Modal chi tiết tài khoản cấp dưới
  const [selectedSeller, setSelectedSeller] = useState({}) // Tài khoản cấp dưới được chọn
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false)

  const handleCloseDrawer = () => {
    setIsOpen(false)
    setActiveKey(['1', '2', '3', '4']) // reset collapse
    setActiveTab('1') // reset active tab
    setSelectedRowKeys([]) // reset checkbox được chọn
    setSelectedFilterDropdown(['active', 'suspend']) // reset filter dropdown
    setKeywordInput('') // reset từ khóa tìm kiếm
    setPage(1) // reset trang hiện tại về 1
    detailForm.resetFields() // reset form
    setIsEdit(false) // reset trạng thái chỉnh sửa
    setDetailAccount({}) // reset chi tiết tài khoản
    setAvatarUrl([]) // reset ảnh đại diện
    setStoreImage([]) // reset ảnh cửa hàng
  }

  const fetchProvince = async () => {
    const res = await ExternalAccountApis.getProvince()
    setListProvince(res?.data?.data ?? [])
  }

  useEffect(() => {
    fetchProvince()
  }, [])

  const fetchDistrict = async (province) => {
    if (province) {
      const res = await ExternalAccountApis.getDistrict(province)
      setListDistrict(res?.data?.data ?? [])
    }
  }

  useEffect(() => {
    if (provinceID) {
      fetchDistrict(provinceID)
    }
  }, [provinceID])

  const fetchWard = async (province, district) => {
    if (province && district) {
      const res = await ExternalAccountApis.getWard(province, district)
      setListWard(res?.data?.data ?? [])
    }
  }

  useEffect(() => {
    if (provinceID && districtID) {
      fetchWard(provinceID, districtID)
    }
  }, [provinceID, districtID])

  const getDetailPartner = async () => {
    setLoadingDetail(true)
    try {
      let rawData
      if (isProfile) {
        rawData = selectedRepresentative
      } else {
        const apiRs = await detailPartnerApi(
          selectedRepresentative?.representative_id,
        )
        rawData = apiRs?.data?.data
      }
      const mappingData = {
        name: rawData?.partner?.name,
        tax_code: rawData?.partner?.tax_code,
        username: rawData?.partner?.username,
        email: rawData?.partner?.email,
        phone_number: rawData?.partner?.phone_number,
        address: rawData?.partner?.address,
        province_id: rawData?.partner?.province_id,
        district_id: rawData?.partner?.district_id,
        ward_id: rawData?.partner?.ward_id,
        representative: {
          full_name: rawData?.full_name,
          email: rawData?.email,
          phone_number: rawData?.phone_number,
          identity_card: rawData?.identity_card,
          gender: rawData?.gender,
          dob: rawData?.dob ? dayjs(rawData?.dob) : null,
          avatar_url: rawData?.avatar_url,
        },
        partner_image_urls: rawData?.partner_image_urls,
        commissions: rawData?.commissions,
        created_at: rawData?.created_at ? dayjs(rawData?.created_at) : null,
        creator: {
          full_name: rawData?.creator?.full_name,
        },
      }
      setDetailAccount(rawData)
      detailForm.setFieldsValue(mappingData)

      // Fill avatar vào form
      setAvatarUrl([
        {
          status: 'done',
          url: rawData?.avatar_url,
        },
      ])

      // Fill ảnh cửa hàng vào form
      setStoreImage(
        rawData?.partner?.partner_image_urls.map((item) => ({
          status: 'done',
          url: item,
        })),
      )

      // Fill tỉnh/thành phố, quận/huyện, phường/xã vào form
      setProvinceID(rawData?.partner?.province_id)
      setDistrictID(rawData?.partner?.district_id)
      setWardID(rawData?.partner?.ward_id)
    } catch (e) {
      console.log(e)
    } finally {
      setLoadingDetail(false)
    }
  }

  useEffect(() => {
    if (selectedRepresentative && isOpen) {
      getDetailPartner()
    }
  }, [selectedRepresentative?.representative_id, isOpen])

  const columns = [
    {
      title: 'Họ tên',
      dataIndex: 'full_name',
      key: 'full_name',
    },
    {
      title: 'Tên TK',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone_number',
      key: 'phone_number',
    },
    {
      title: 'CCCD/CMND',
      dataIndex: 'identity_card',
      key: 'identity_card',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => (
        <span className={`tag tag-${status}`}>{getTextTag[status]}</span>
      ),
    },
  ]

  const getListSeller = async () => {
    setTableLoading(true)
    try {
      const apiRs = await listSellers(
        selectedRepresentative?.representative_id,
        {
          limit: DEFAULT_PAGINATE_SIZE,
          page,
          search_text: keywordSearch,
          statuses: selectedFilterDropdown,
        },
      )
      setSellerList(
        apiRs?.data?.data?.items.map((item) => ({
          ...item,
          key: item?.id, // add key for logic checkbox
        })),
      )

      setTotal(apiRs?.data?.data?.meta?.total)
    } catch (e) {
      console.log(e)
    } finally {
      setTableLoading(false)
    }
  }

  useEffect(() => {
    if (selectedRepresentative?.representative_id && !isProfile) {
      getListSeller()
    }
  }, [
    selectedRepresentative?.representative_id,
    page,
    keywordSearch,
    selectedFilterDropdown,
    isProfile,
  ])

  const handleChangeKeyword = (e) => {
    setKeywordInput(e.target.value)
    setPage(1)
  }

  const handleClickDropDown = ({ key }) => {
    if (key === 'edit') {
      setIsEdit(true)
    }

    if (key === 'reset_password') {
      setIsOpenModalResetPassword(true)
    }
  }

  useEffect(() => {
    if (isOpen) {
      detailForm.setFieldValue(
        ['representative', 'avatar_url'],
        avatarUrl[0]?.url,
      )
      detailForm.validateFields([['representative', 'avatar_url']])
    }
  }, [avatarUrl])

  useEffect(() => {
    if (isOpen) {
      detailForm.setFieldValue(
        'partner_image_urls',
        storeImage.map((item) => item.url),
      )
      detailForm.validateFields(['partner_image_urls'])
    }
  }, [storeImage])

  const handleClickButtonChangeStatus = () => {
    setIsOpenModalChangeStatus(true)
  }

  const tabBarExtraContent = (
    <div className="wrap-btn">
      {isHasPermission(PERMISSION.EDIT_PARTNER_ACCOUNT) && (
        <>
          <Button
            className={detailAccount?.partner?.status === 'active' ? 'btn-red' : 'btn-green'}
            onClick={handleClickButtonChangeStatus}
          >
            {detailAccount?.partner?.status === 'active'
              ? 'Vô hiệu hoá'
              : 'Kích hoạt'}
          </Button>
          <Dropdown
            menu={{
              items: [
                {
                  label: (
                    <div
                      className="btn-dropdown"
                      role="button"
                      tabIndex={0}
                    >
                      <img
                        width={24}
                        height={24}
                        alt="edit"
                        src="/images/common/edit.svg"
                      />
                      Sửa thông tin
                    </div>
                  ),
                  key: 'edit',
                  disabled: false,
                },
                {
                  label: (
                    <div
                      className="btn-dropdown"
                      role="presentation"
                    >
                      <img
                        width={24}
                        height={24}
                        alt="reset_password"
                        src="/images/common/reset.svg"
                      />
                      Thiết lập lại mật khẩu
                    </div>
                  ),
                  key: 'reset_password',
                },
                isHasPermission(PERMISSION.DELETE_ACCOUNT) && {
                  label: (
                    <div
                      className="btn-dropdown text-red"
                      role="presentation"
                      onClick={() => {
                        setIsOpenModalDelete(true)
                      }}
                    >
                      <img
                        width={24}
                        height={24}
                        alt="edit"
                        src="/images/common/recycle_red_fill.svg"
                      />
                      Xóa tài khoản
                    </div>
                  ),
                  key: '2',
                },
              ],
              onClick: handleClickDropDown,
            }}
            trigger={['click']}
            overlayClassName="sim-dropdown"
          >
            <img
              alt="show-more"
              width={40}
              height={40}
              src="/images/common/show-more.svg"
            />
          </Dropdown>
        </>
      )}

    </div>
  )

  const editPartner = async () => {
    setLoadingEdit(true)
    try {
      const data = detailForm.getFieldsValue()
      await editPartnerApi(selectedRepresentative?.representative_id, {
        ...data,
      })
      message.success('Chỉnh sửa tài khoản thành công')
      setIsEdit(false)
      getDetailPartner()
      getListPartner()
    } catch (e) {
      console.log(e)
      message.error('Có lỗi xảy ra, vui lòng thử lại!')
    } finally {
      setLoadingEdit(false)
    }
  }

  const handleClickCancel = () => {
    setIsEdit(false)
    getDetailPartner()
  }
  const handleClickEdit = () => {
    detailForm.submit()
  }

  const handleChangeProvince = (value) => {
    setProvinceID(value)
    setWardID(null)
    setDistrictID(null)
    detailForm.setFieldValue('district_id', null)
    detailForm.setFieldValue('ward_id', null)
    setListDistrict([])
    setListWard([])
  }

  const handleChangeDistrict = (value) => {
    setDistrictID(value)
    detailForm.setFieldValue('ward_id', null)
  }

  const handleChangeWard = (value) => {
    setWardID(value)
  }

  const paginationConfig = useMemo(
    () => ({
      defaultPageSize: DEFAULT_PAGINATE_SIZE, // Set initial page size
      onChange: (pageNumber, pageSize) => {
        setPage(pageNumber)
      },
      showSizeChanger: false,
      total,
      current: parseInt(page, 10),
    }),
    [total, page],
  )

  const deleteAccounts = async () => {
    try {
      await deleteSellerApi({
        seller_ids: selectedRowKeys.join(','),
      })
      setIsOpenDeleteModal(false)
      getListSeller()
      message.success('Xoá tài khoản thành công')
    } catch (e) {
      message.error('Có lỗi xảy ra, vui lòng thử lại!')
    }
  }

  const handleDeleteAccount = async () => {
    try {
      await ExternalAccountApis.deleteAccountPartner(selectedRepresentative?.id)
      setIsOpenModalDelete(false)
      setIsOpen(false)
      getListPartner()
      message.success('Xóa tài khoản thành công')
    } catch {
      message.error('Xóa tài khoản thất bại')
    }
  }

  return (
    <>
      <Drawer
        open={isOpen}
        onClose={handleCloseDrawer}
        title={isProfile ? 'Chi tiết tài khoản' : 'Chi tiết tài khoản đối tác'}
        width={800}
        className="drawer-common drawer-partner-account"
        extra={
        isEdit
        && !isProfile && (
          <div className="drawer-extra">
            <Button onClick={handleClickCancel}>Hủy</Button>

            <Button
              className="btn-green"
              onClick={handleClickEdit}
              loading={loadingEdit}
            >
              Chỉnh sửa tài khoản
            </Button>
          </div>
        )
      }
      >
        {loadingDetail ? (
          <div className="loading-block">
            <Spin />
          </div>
        ) : (
          <Tabs
            activeKey={activeTab}
            onChange={(key) => setActiveTab(key)}
            tabBarExtraContent={!isProfile && tabBarExtraContent}
          >
            <Tabs.TabPane
              key={1}
              tab={isProfile ? 'Tổng quan' : 'Thông tin tổ chức'}
            >
              {detailAccount?.deactivate_note && (
                <div className="account-reason">
                  <div className="account-reason-title">
                    Lý do:
                    <span className="account-reason-content">
                      {detailAccount?.deactivate_note}
                    </span>
                  </div>
                </div>
              )}
              {isProfile && (
                <>
                  <div
                    className="account-avatar"
                    style={{
                      height: '288px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <UploadImageComponent
                      withoutLogo
                      imageUrl={detailAccount?.avatar_url}
                      validate
                      onImageError={(e) => {
                        e.target.src = DEFAULT_IMAGE
                      }}
                    />
                  </div>
                  <div className="account-fullname">
                    {detailAccount?.full_name}
                  </div>
                  <div className="account-status">
                    <div className="tag-wrapper">
                      <div
                        className={`tag tag-${detailAccount?.partner?.status?.toLocaleLowerCase()}`}
                      >
                        {
                        getTextTag[
                          detailAccount?.partner?.status?.toLocaleLowerCase()
                        ]
                      }
                      </div>
                    </div>
                  </div>
                </>
              )}
              <Form
                layout="vertical"
                form={detailForm}
                disabled={!isEdit} // disable form khi không ở trạng thái chỉnh sửa
                onFinish={editPartner}
              >
                <Collapse activeKey={activeKey}>
                  {/* Thông tin tổ chức */}
                  <Collapse.Panel header="Thông tin tổ chức" key="1">
                    <div className="form-divide">
                      <div className="child-form">
                        <Form.Item
                          label="Tên tổ chức"
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: 'Vui lòng nhập tên tổ chức',
                            },
                          ]}
                        >
                          <Input placeholder="Nhập tên tổ chức" />
                        </Form.Item>
                      </div>

                      <div className="child-form">
                        <Form.Item
                          label="Mã số thuế"
                          name="tax_code"
                          rules={[
                            {
                              required: true,
                              message: 'Vui lòng nhập mã số thuế',
                            },
                          ]}
                        >
                          <Input placeholder="Nhâp mã số thuế" disabled />
                        </Form.Item>
                      </div>
                    </div>

                    <div className="form-divide">
                      <div className="child-form">
                        <Form.Item
                          label="Tên tài khoản"
                          name="username"
                          rules={[
                            {
                              required: true,
                              message: 'Vui lòng nhập tên tài khoản công ty',
                            },
                          ]}
                        >
                          <Input placeholder="Nhập tên tài khoản" disabled />
                        </Form.Item>
                      </div>

                      <div className="child-form">
                        <Form.Item
                          label="Email"
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: 'Vui lòng nhập email công ty',
                            },
                            {
                              type: 'email',
                              message: 'Địa chỉ email không hợp lệ',
                            },
                          ]}
                        >
                          <Input placeholder="Nhập email" />
                        </Form.Item>
                      </div>
                    </div>

                    <div className="form-divide">
                      <div className="child-form">
                        <Form.Item
                          label="Số điện thoại"
                          name="phone_number"
                          rules={[
                            {
                              required: true,
                              message: 'Vui lòng nhập số điện thoại công ty',
                            },
                            {
                              pattern: regexPhoneNumber,
                              message: 'Vui lòng nhập đúng số điện thoại!',
                            },
                          ]}
                        >
                          <Input placeholder="Nhập số điện thoại" />
                        </Form.Item>
                      </div>
                      <div className="child-form">
                        <Form.Item
                          label="Địa chỉ"
                          name="address"
                          rules={[
                            {
                              required: true,
                              message: 'Vui lòng nhập địa chỉ công ty',
                            },
                          ]}
                        >
                          <Input placeholder="Nhập địa chỉ" />
                        </Form.Item>
                      </div>
                    </div>

                    <div className="form-divide">
                      <div className="child-form">
                        <Form.Item
                          label="Tỉnh/Thành phố"
                          name="province_id"
                          rules={[
                            {
                              required: true,
                              message: 'Vui lòng chọn tỉnh/thành phố',
                            },
                          ]}
                        >
                          <Select
                            placeholder="Chọn Tỉnh/ Thành Phố"
                            size="large"
                            allowClear
                            showSearch
                            optionFilterProp="name"
                            filterOption={(input, option) => option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0}
                          // value={provinceID}
                          // onChange={(value) => setProvinceID(value)}
                            onChange={handleChangeProvince}
                          >
                            {(listProvince || []).map((province) => (
                              <Select.Option
                                key={province?.id}
                                value={province?.id}
                              >
                                {province?.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                      <div className="child-form">
                        <Form.Item
                          label="Quận/Huyện"
                          name="district_id"
                          rules={[
                            {
                              required: true,
                              message: 'Vui lòng chọn quận/huyện',
                            },
                          ]}
                        >
                          <Select
                            style={{ width: '100%' }}
                            placeholder="Chọn Quận/ Huyện"
                            size="large"
                            allowClear
                          // value={districtID}
                            optionFilterProp="name"
                            filterOption={(input, option) => option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0}
                            onChange={handleChangeDistrict}
                            showSearch
                          >
                            {(listDistrict || []).map((district) => (
                              <Select.Option
                                key={district?.id}
                                value={district?.id}
                              >
                                {district?.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>

                    <div className="form-divide">
                      <div className="child-form">
                        <Form.Item
                          label="Phường/Xã"
                          name="ward_id"
                          rules={[
                            {
                              required: true,
                              message: 'Vui lòng chọn phường/xã',
                            },
                          ]}
                        >
                          <Select
                            placeholder="Chọn Phường /Xã"
                            size="large"
                            style={{ width: '100%' }}
                            allowClear
                            showSearch
                            optionFilterProp="name"
                            filterOption={(input, option) => option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0}
                          // value={wardID}
                            onChange={handleChangeWard}
                          >
                            {(listWard || []).map((ward) => (
                              <Select.Option key={ward?.id} value={ward?.id}>
                                {ward?.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                      <div className="child-form" />
                    </div>

                    <Form.Item
                      label="Ảnh cửa hàng"
                      name="partner_image_urls"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng tải lên ảnh cửa hàng',
                        },
                      ]}
                    >
                      <ImageArrayUpload
                        fileList={storeImage}
                        setFileList={setStoreImage}
                        isDisable={!isEdit}
                      />
                    </Form.Item>
                  </Collapse.Panel>

                  {/* Thông tin pháp nhân */}
                  <Collapse.Panel header="Thông tin pháp nhân" key="2">
                    {/* <Form.Item name={'representative'}> */}
                    {/* form-divde Họ tên, email */}
                    <div className="form-divide">
                      <div className="child-form">
                        <Form.Item
                          label="Họ tên"
                          name={['representative', 'full_name']}
                          rules={[
                            {
                              required: true,
                              message: 'Vui lòng nhập họ tên người đại diện',
                            },
                          ]}
                        >
                          <Input placeholder="Nhập họ và tên" />
                        </Form.Item>
                      </div>
                      <div className="child-form">
                        <Form.Item
                          label="Email"
                          name={['representative', 'email']}
                          rules={[
                            {
                              required: true,
                              message: 'Vui lòng nhập email người đại diện',
                            },
                            {
                              type: 'email',
                              message: 'Địa chỉ email không hợp lệ',
                            },
                          ]}
                        >
                          <Input placeholder="Nhập email" />
                        </Form.Item>
                      </div>
                    </div>

                    {/* Số điện thoại, số cccd/cmnd */}
                    <div className="form-divide">
                      <div className="child-form">
                        <Form.Item
                          label="Số điện thoại"
                          name={['representative', 'phone_number']}
                          rules={[
                            {
                              required: true,
                              message:
                              'Vui lòng nhập số điện thoại người đại diện',
                            },
                            {
                              pattern: regexPhoneNumber,
                              message: 'Vui lòng nhập đúng số điện thoại!',
                            },
                          ]}
                        >
                          <Input placeholder="Nhập số điện thoại" />
                        </Form.Item>
                      </div>

                      <div className="child-form">
                        <Form.Item
                          label="Số CCCD/CMND"
                          name={['representative', 'identity_card']}
                          rules={[
                            {
                              required: true,
                              message:
                              'Vui lòng nhập số CCCD/CMND người đại diện',
                            },
                            {
                              min: 9,
                              message: 'Vui lòng nhập đủ Số CMND/CCCD',
                            },
                            {
                              max: 12,
                              message: 'Vui lòng nhập đủ Số CMND/CCCD',
                            },
                            {
                              pattern: /^[0-9]*$/,
                              message:
                              'Vui lòng nhập đúng định dạng Số CMND/CCCD',
                            },
                          ]}
                        >
                          <Input placeholder="Nhập số CMND/CCCD" />
                        </Form.Item>
                      </div>
                    </div>

                    {/* Giới tính, Ngày sinh */}
                    <div className="form-divide">
                      <div className="child-form">
                        <Form.Item
                          label="Giới tính"
                          name={['representative', 'gender']}
                          rules={[
                            {
                              required: true,
                              message: 'Vui lòng chọn giới tính',
                            },
                          ]}
                        >
                          <Select placeholder="Chọn giới tính">
                            {GENDER_LIST.map((gender) => (
                              <Select.Option
                                key={gender.key}
                                value={gender.value}
                              >
                                {gender.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>

                      <div className="child-form">
                        <Form.Item
                          label="Ngày sinh"
                          name={['representative', 'dob']}
                          rules={[
                            {
                              required: true,
                              message: 'Vui lòng nhập ngày sinh',
                            },
                          ]}
                        >
                          <DatePicker
                            placeholder="Nhập ngày sinh"
                            disabledDate={(current) => current > dayjs()}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    {!isProfile && (
                      <Form.Item
                        label="Ảnh chân dung"
                        name={['representative', 'avatar_url']}
                        rules={[
                          {
                            required: true,
                            message: 'Vui lòng tải lên ảnh avatar',
                          },
                        ]}
                      >
                        <ImageArrayUpload
                          maxLength={1}
                          fileList={avatarUrl}
                          setFileList={setAvatarUrl}
                        />
                      </Form.Item>
                    )}
                  </Collapse.Panel>

                  {/* Thông tin hoa hồng */}
                  <Collapse.Panel header="Thông tin hoa hồng" key="3">
                    <Form.List name="commissions">
                      {(fields, { add, remove }) => (
                        <>
                          {fields?.length > 0 && (
                            <div className="commission-item commission-header">
                              <span className="commission-field">
                                Loại sản phẩm
                              </span>
                              <span className="commission-field">
                                Tên sản phẩm
                              </span>
                              <span className="commission-field">Hoa hồng</span>
                              <MinusCircleOutlined
                                style={{ visibility: 'hidden' }}
                              />
                            </div>
                          )}
                          {fields.map(({ key, name, ...restField }) => (
                            <div
                              key={key}
                              style={{ display: 'flex', marginBottom: 8 }}
                              className="commission-item"
                            >
                              <Form.Item
                                {...restField}
                                name={[name, 'product_type']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Vui lòng chọn loại sản phẩm',
                                  },
                                ]}
                                className="commission-field"
                              >
                                <Select placeholder="Loại sản phẩm">
                                  {COMMISSION_PRODUCT.map((product) => (
                                    <Select.Option
                                      key={product.value}
                                      value={product.value}
                                    >
                                      {product.label}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>

                              <Form.Item
                                {...restField}
                                name={[name, 'name']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Nhập tên sản phẩm',
                                  },
                                ]}
                                className="commission-field"
                              >
                                <Input placeholder="Nhập tên sản phẩm" />
                              </Form.Item>

                              <Form.Item
                                {...restField}
                                name={[name, 'commission_rate']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Vui lòng nhập % hoa hồng',
                                  },
                                ]}
                                className="commission-field"
                              >
                                <InputNumber
                                  placeholder="% hoa hồng"
                                  controls={false}
                                />
                              </Form.Item>
                              {/* <MinusCircleOutlined onClick={() => { */}
                              {/*  if (isEdit) { */}
                              {/*    remove(name) */}
                              {/*  } */}
                              {/* }} */}
                              {/* /> */}
                            </div>
                          ))}
                          {/* <Form.Item> */}
                          {/*  <Button className="display-center" type="dashed" onClick={() => add()} block icon={<PlusOutlined />}> */}
                          {/*    Thêm thông tin hoa hồng */}
                          {/*  </Button> */}
                          {/* </Form.Item> */}
                        </>
                      )}
                    </Form.List>
                  </Collapse.Panel>

                  {/* Thông tin khác */}
                  <Collapse.Panel header="Thông tin khác" key="4">
                    <div className="form-divide">
                      <div className="child-form">
                        <Form.Item
                          label="Người tạo tài khoản"
                          name={['creator', 'full_name']}
                        >
                          <Input disabled />
                        </Form.Item>
                      </div>
                      <div className="child-form">
                        <Form.Item label="Ngày tạo tài khoản" name="created_at">
                          <DatePicker disabled />
                        </Form.Item>
                      </div>
                    </div>
                  </Collapse.Panel>
                </Collapse>
              </Form>
            </Tabs.TabPane>
            {!isProfile && (
              <Tabs.TabPane key={2} tab="Danh sách TK cấp dưới">
                <>
                  <div className="action-block">
                    <Dropdown
                      menu={{
                        items: [
                          {
                            key: 'active',
                            label: 'Hoạt động',
                            icon: (
                              <img src="/images/common/check.svg" alt="icon" />
                            ),
                          },
                          {
                            key: 'suspend',
                            label: 'Tạm ngừng',
                            icon: (
                              <img src="/images/common/check.svg" alt="icon" />
                            ),
                          },
                        ],
                        selectable: true,
                        multiple: true,
                        selectedKeys: selectedFilterDropdown,
                        onSelect: (value) => setSelectedFilterDropdown(value?.selectedKeys),
                        onDeselect: (value) => setSelectedFilterDropdown(value?.selectedKeys),
                      }}
                      trigger={['click']}
                      className="action-filter"
                      rootClassName="filter-dropdown"
                    >
                      <div>
                        Trạng thái tài khoản
                        <CaretDownFilled />
                      </div>
                    </Dropdown>
                    <Input
                      value={keywordInput}
                      onChange={handleChangeKeyword}
                      className="action-search"
                      placeholder="Tìm kiếm"
                      size="large"
                      prefix={<SearchOutlined />}
                    />
                    <Button
                      classNames="action-delete"
                      disabled={selectedRowKeys?.length === 0}
                      onClick={() => setIsOpenDeleteModal(true)}
                      className="btn-red"
                    >
                      Xóa
                    </Button>
                  </div>

                  {/* Table list tk cấp dưới */}
                  <Table
                    loading={tableLoading}
                    columns={columns}
                    dataSource={sellerList}
                    rowSelection={{
                      type: 'checkbox',
                      selectedRowKeys,
                      hideSelectAll: true,
                      onChange: (newSelectedRows) => {
                        setSelectedRowKeys(newSelectedRows)
                      },
                    }}
                    onRow={(record) => ({
                      onClick: () => {
                        setIsOpenDetailSeller(true)
                        setSelectedSeller(record)
                      },
                    })}
                    pagination={paginationConfig}
                  />
                </>
              </Tabs.TabPane>
            )}
          </Tabs>
        )}

        <ModalDelete
          isOpen={isOpenDeleteModal}
          setIsOpen={setIsOpenDeleteModal}
          deleteAccounts={deleteAccounts}
        />

        <ModalChangeStatusPartner
          isOpen={isOpenModalChangeStatus}
          setIsOpen={setIsOpenModalChangeStatus}
          accountStatus={detailAccount?.partner?.status}
          representativeId={detailAccount?.partner?.representative_id}
          getDetailPartner={getDetailPartner}
          getListPartner={getListPartner}
          getListSeller={getListSeller}
        />

        <ModalResetPassword
          isOpen={isOpenModalResetPassword}
          setIsOpen={setIsOpenModalResetPassword}
          username={detailAccount?.username}
        />

        <DetailSeller
          isOpen={isOpenDetailSeller}
          setIsOpen={setIsOpenDetailSeller}
          getListSeller={getListSeller}
          id={selectedSeller?.id}
          status={selectedSeller?.status}
          setStatus={(value) => {
            setSelectedSeller({ ...selectedSeller, status: value })
          }}
        />
      </Drawer>
      <Modal
        centered
        open={isOpenModalDelete}
        onCancel={() => setIsOpenModalDelete(false)}
        onOk={handleDeleteAccount}
        wrapClassName="modal-activate-sim modal-reject"
        okText="Xác nhận"
        cancelText="Hủy"
        style={{ width: 456 }}
      >
        <div className="modal-content">
          <div className="reject-info">
            <div className="modal-title">Xác nhận xoá tài khoản tài khoản?</div>
            <div className="modal-description">
              Tài khoản này sẽ không còn tồn tại trong hệ thống. Vui lòng xác nhận
            </div>
          </div>

        </div>
      </Modal>
    </>

  )
}

export default DetailPartner
