import { Link } from 'react-router-dom'
import { Spin } from 'antd'
import { useEffect } from 'react'
import navs, { url as navsURL } from '../../constants/navs'
import NavItem from './navItem'
import { enableScroll, isMobileDevice } from '../../util'
import { handlePermission, isHasPermission } from '../../redux/reducers/permission'
import { PERMISSION } from '../../constants/permission'
import { get, KEYS } from '../../util/localStorage'

const SideBar = (props) => {
  const { isShowSideBar, setIsShowSideBar, isLoadingRole } = props
  const currentPath = window.location.pathname

  const handleClickOutside = (e) => {
    const parent = e.target.closest('.side-bar')
    if (!parent) {
      // e.preventDefault()
      e.stopPropagation()
      setIsShowSideBar(false)
      enableScroll()
    }
  }

  useEffect(() => {
    if (isMobileDevice() && isShowSideBar) {
      document.addEventListener('click', handleClickOutside, true)
    }
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [isShowSideBar])

  const isPermission = (url) => {
    const userPermission = handlePermission(get(KEYS.MY_ROLES))
    switch (url) {
      case navsURL.manage_bonus_point:
        return isHasPermission(PERMISSION.MANGAGE_BONUS, userPermission)
      case navsURL.package_manage:
        return isHasPermission(PERMISSION.GET_LIST_PACKAGED_SIM, userPermission)
      case navsURL.website_manage:
        return isHasPermission([PERMISSION.VIEW_SETTING, PERMISSION.VIEW_SETTING_DL_M2M_CHINA], userPermission)
      case navsURL.sim_package:
        return isHasPermission(PERMISSION.VISIBLE_SIM_GOI, userPermission)
      case navsURL.sim_number:
        return isHasPermission(PERMISSION.VISIBLE_SIM_SO, userPermission)
      case navsURL.sim_travel:
        return isHasPermission(PERMISSION.VISIBLE_SIM_M2M_DU_LICH, userPermission)
      case navsURL.sim_m2m:
        return isHasPermission(PERMISSION.VISIBLE_SIM_M2M_DU_LICH, userPermission) || isHasPermission(PERMISSION.VISIBLE_SIM_M2M, userPermission)
      case navsURL.account_management:
        return isHasPermission(PERMISSION.VIEW_NAV_ACCOUNT, userPermission)
      case navsURL.order_management:
        return isHasPermission(PERMISSION.VIEW_LIST_ORDER, userPermission)
      case navsURL.ticket_management:
        return isHasPermission(PERMISSION.VIEW_LIST_TICKET, userPermission)
      case navsURL.product_management:
        return isHasPermission(PERMISSION.VIEW_PRODUCT_PORTFOLIO, userPermission)
        || isHasPermission(PERMISSION.VIEW_LIST_PRODUCT, userPermission)
        || isHasPermission([PERMISSION.CREATE_IMPORT_REQUEST, PERMISSION.CREATE_EXPORT_REQUEST], userPermission)
        || isHasPermission(PERMISSION.CREATE_IMPORT_EXPORT_WARRANTY_MANAGEMENT_REQUEST, userPermission)
      case navsURL.setting_management:
        return isHasPermission(PERMISSION.DISCOUNT_CARD_MANAGEMENT, userPermission)
      case navsURL.manage_blog:
        return isHasPermission(PERMISSION.VIEW_NAVIGATE_BLOG, userPermission)
      case navsURL.cross_check:
        return isHasPermission(PERMISSION.CROSS_CHECK, userPermission)
      case navsURL.flash_sale:
        return isHasPermission(PERMISSION.FLASH_SALE, userPermission)
      default:
        return true
    }
  }

  return (
    <>
      <div
        className={`side-bar ${
          isShowSideBar ? 'sidebar-active' : 'side-bar-inactive'
        }`}
      >
        <div className="placeholder-header" />
        {
          isMobileDevice()
          && (
          <Link to="/" className="logo">
            <img src="/images/common/logo-v6.png" alt="logo" />
          </Link>
          )
        }

        <div className="nav-list nav-list-all">
          {!isLoadingRole ? navs.map((item, index) => (
            <div key={index} className="nav-group">
              {item.name && item?.group?.some?.((x) => isPermission(x.url)) && <div className="nav-group-name">{item.name}</div>}
              {item.group.map((x) => (
                isPermission(x.url) && (
                  <NavItem
                    key={x.name}
                    item={x}
                    currentPath={currentPath}
                    setIsShowSideBar={setIsShowSideBar}
                  />
                )
              ))}
            </div>
          )) : <Spin />}
        </div>
      </div>
    </>

  )
}

export default SideBar
