import { useEffect, useState } from 'react'
import {
  Checkbox, Form, Input, message, Space,
} from 'antd'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { signInApi } from '../../apis/authenticationApis'
import { KEYS, set } from '../../util/localStorage'
import Axios from '../../services/Axios'
import ModalChangePassword from './modalChangePassword'
import { DEFAULT_PASSWORD } from '../../constants/constant'

const specialAccount = ['superadmin']

const LoginPage = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [isOpenModalChangePassword, setIsOpenModalChangePassword] = useState(false)

  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`)
  }

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    if (name === 'username') {
      setUsername(value)
    } else if (name === 'password') {
      setPassword(value)
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    try {
      const res = await signInApi(username?.trim(), password)
      const accessToken = res?.data?.data?.access_token
      if (accessToken) {        
        Axios.getInstance().setToken(accessToken)
        set(KEYS.TOKEN, accessToken)
        if (password === DEFAULT_PASSWORD) {
          setIsOpenModalChangePassword(true)
        } else {
          window.location = '/home'
        }
      } else {
        message.error('Đăng nhập không thành công')
      }
    } catch (err) {
      if (err?.response?.data?.message === 'Forbidden') {
        message.error('Tài khoản bị vô hiệu hóa')
      } else if (err?.response?.data?.message?.includes('E10001')) {
        message.error('User không tồn tại')
      } else if (
        err?.response?.data?.message
        === 'E10004: Incorrect username or password'
      ) {
        message.error('Sai tên đăng nhập hoặc mật khẩu. Vui lòng kiểm tra')
      } else {
        message.error('Có lỗi khi đăng nhập')
      }
    }
  }

  const handleShowChangePasswordSuccesfully = () => {
    setIsOpenModalChangePassword(false)
    message.success(
      'Đổi mật khẩu thành công. Vui lòng đăng nhập lại vào hệ thống',
    )
    setUsername('')
    setPassword('')
  }

  return (
    <div className="login-page">
      <div className="login-bg">
        <div className="popup-login">
          {/* <div className="left-content">
            <img src="/images/login/left-img.png" alt="left-img" />
          </div> */}
          <div className="right-content">
            <div className="logo-header">
              <img src="/images/common/logo-v6.png" alt="logo" />
            </div>
            <div className="form-login">
              <h1>Xin chào!</h1>
              {/* <div className="sub-des">Lorem ipsum dolor sit amet</div> */}
              <form onSubmit={onSubmit}>
                <div className="input-login-item">
                  <div className="label-input">Tài khoản</div>
                  <Input
                    value={username}
                    name="username"
                    onChange={handleChange}
                    placeholder="Nhập tài khoản"
                    className="form-input"
                    required
                    size="large"
                  />
                </div>
                <div className="input-login-item">
                  <div className="label-input">Mật khẩu</div>
                  <Input.Password
                    value={password}
                    name="password"
                    onChange={handleChange}
                    placeholder="Nhập mật khẩu"
                    className="form-input"
                    iconRender={(visible) => visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />}
                    required
                  />
                </div>
                <div className="d-flex justify-content-between">
                  <div>
                    {/* <Checkbox activeColor="#00BA34" onChange={onChange}><div className="remember-me">Remember me</div></Checkbox> */}
                  </div>
                  {/* <a href="#" className="forget-password">Quên mật khẩu?</a> */}
                </div>
                <button type="submit" className="btn-login">
                  Đăng nhập
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ModalChangePassword
        isOpen={isOpenModalChangePassword}
        setIsOpen={setIsOpenModalChangePassword}
        onSuccess={handleShowChangePasswordSuccesfully}
      />
    </div>
  )
}
export default LoginPage
