export const url = {
  // quản lý tài khoản
  account_management: '/quan-ly-tai-khoan',
  internal_account: '/quan-ly-tai-khoan/tai-khoan-noi-bo',
  external_account: '/quan-ly-tai-khoan/tai-khoan-ngoai',
  partner_account: '/quan-ly-tai-khoan/tai-khoan-doi-tac',

  // Website
  website_manage: '/quan-ly-website',
  website_customer: '/quan-ly-website/trang-khach-hang/',
  website_dl: '/quan-ly-website/dl',
  website_m2m: '/quan-ly-website/m2m',
  website_china: '/quan-ly-website/china',
  website_taiwan: '/quan-ly-website/taiwan',

  // // Đơn hàng
  // order_management: '/quan-ly-don-hang',
  // sim_order_management: '/quan-ly-don-hang/quan-ly-sim-so',
  // topup_order_management: '/quan-ly-don-hang/nap-the',
  // provider_order_management: '/quan-ly-don-hang/nha-mang',

  // Quản lý Ticket
  ticket_management: '/quan-ly-ticket',

  // Quản lý sản phẩm
  product_management: '/quan-ly-san-pham',
  product_portfolio: '/quan-ly-san-pham/danh-muc',
  product: '/quan-ly-san-pham/san-pham',
  product_inventory: '/quan-ly-san-pham/quan-ly-kho',
  product_warranty_management: '/quan-ly-san-pham/kho-bao-hanh',

  // thiết lập chung
  setting_management: '/thiet-lap-chung',
  // discount_card_management: '/thiet-lap-chung/nap-the',

  // quản lý bài viết
  manage_blog: '/quan-ly-bai-viet',

}

const navs = [
  {
    name: 'Nền tảng',
    group: [
      {
        name: 'Website',
        url: url.website_manage,
        icon: 'catalog.svg',
        children: [
          {
            name: 'Trang khách hàng',
            url: url.website_customer,
          },
        ],
      },
      {
        name: 'Quản lý bài viết',
        url: url.manage_blog,
        icon: 'quanlybaiviet.svg',
      },
      // {
      //   name: 'Đơn hàng',
      //   url: url.order_management,
      //   icon: 'quanlydonhang.svg',
      //   children: [
      //     {
      //       name: 'Sim số Simplus',
      //       url: url.sim_order_management,
      //     },
      //     {
      //       name: 'Sim số nhà mạng',
      //       url: url.provider_order_management,
      //     },
      //     {
      //       name: 'Thẻ cào',
      //       url: url.topup_order_management,
      //     },
      //   ],
      // },
      {
        name: 'Quản lý Ticket',
        url: url.ticket_management,
        icon: 'quanlyticket.svg',
      },
    ],
  },
  {
    name: 'Sản phẩm',
    group: [
      {
        name: 'Quản lý sản phẩm',
        url: url.product_management,
        icon: 'quanlysanpham.svg',
        children: [
          {
            name: 'Danh mục sản phẩm',
            url: url.product_portfolio,
          },
          {
            name: 'Danh sách sản phẩm',
            url: url.product,
          },
          {
            name: 'QL xuất/ nhập kho',
            url: url.product_inventory,
          },
          // {
          //   name: 'QL bảo hành',
          //   url: url.product_warranty_management,
          // },
        ],
      },
    ],
  },
  {
    name: 'Cài đặt',
    group: [
      {
        name: 'Quản lý tài khoản',
        url: url.account_management,
        icon: 'quanlytaikhoan.svg',
        children: [
          {
            name: 'Tài khoản nội bộ',
            url: url.internal_account,
          },
          // {
          //   name: 'Tài khoản ngoài',
          //   url: url.external_account,
          // },
          // {
          //   name: 'Tài khoản đối tác',
          //   url: url.partner_account,
          // },
        ],
      },
    ],
  },
]

export default navs
