export const ROLE = {
  SUPER_ADMIN: 'super_admin',
  ADMIN: 'admin',
  MKT: 'mkt',
  CTV: 'ctv',
}

export const ROLE_INTERNAL_DIAGRAM = {
  role: ROLE.SUPER_ADMIN,
  level: 0,
  children: [
    {
      role: ROLE.ADMIN,
      level: 1,
      children: [
        {
          role: ROLE.MKT,
          level: 2,
        },
        {
          role: ROLE.CTV,
          level: 2,
        },
      ],
    },
  ],
}

export const PERMISSION = {
  // MANAGE BONUS
  MANGAGE_BONUS: 'MANGAGE_BONUS', // QUAN LY DIEM THUONG
  VIEW_REQUEST_CHANGE_POINT: 'VIEW_REQUEST_CHANGE_POINT', // XEM_YEU_CAU_DOI_DIEM
  APPROVE_BONUS: 'APPROVE_BONUS', // PHE DUYET DIEM THUONG

  // SIM
  CHANGE_STATUS_TO_SOLD: 'CHANGE_STATUS_TO_SOLD', // CHUYEN_TRANG_THAI_SANG_DA_BAN

  // SIM_GOI
  UPLOAD_LIST_SIM_GOI: 'UPLOAD_LIST_SIM_GOI', // UPLOAD_DANH_SACH_SIM
  APPROVE_LIST_UPLOAD_SIM_GOI: 'APPROVE_LIST_UPLOAD_SIM_GOI', // QUAN LY DANH SACH UPLOAD CAN PHE DUYET
  UPDATE_LIST_UPLOAD_SIM_GOI: 'UPDATE_LIST_UPLOAD_SIM_GOI', // PHE_DUYET_DANH_SACH_UPLOAD (TU CHOI HOAC DUYET)
  MANAGE_SIM_WAREHOUSE_SIM_GOI: 'MANAGE_SIM_WAREHOUSE_SIM_GOI', // QUAN_LY_KHO_SIM (THẤY NAVIGATOR QUAN LY KHO SIM GÓI)
  MANAGE_SIM_ACTIVATION_SIM_GOI: 'MANAGE_SIM_ACTIVATION_SIM_GOI', // QUAN_LY_KICH_HOAT_SIM
  EDIT_DETAIL_SIM_WAREHOUSE_SIM_GOI: 'EDIT_DETAIL_SIM_WAREHOUSE_SIM_GOI', // SUA_THONG_TIN_GOI_CUOC
  VISIBLE_SIM_GOI: 'VISIBLE_SIM_GOI',
  FILTER_SIM_GOI: 'FILTER_SIM_GOI', // BO_LOC_DANH_SACH_SIM_GOI
  FILTER_BY_SELLER_SIM_PACKAGED: 'FILTER_BY_SELLER_SIM_PACKAGED', // LOC DANH SACH SIM GOI THEO SELLER
  // // SIM_GOI detail
  RESTORE_LIST_SIM_GOI: 'RESTORE_LIST_SIM_GOI', // KHOI_PHUC_SIM_GOI
  REMOVE_NUMBER_SIM_GOI: 'REMOVE_NUMBER_SIM_GOI', // GO_SO_SIM_GOI
  EDIT_BUTTON_DETAIL_SIM_GOI: 'EDIT_BUTTON_DETAIL_SIM_GOI', // BUTTON_SUA_DETAIL_SIM_GOI
  REMOVE_BUTTON_DETAIL_SIM_GOI: 'REMOVE_BUTTON_DETAIL_SIM_GOI', // BUTTON_GO_DETAIL_SIM_GOI
  RESTORE_BUTTON_DETAIL_SIM_GOI: 'RESTORE_BUTTON_DETAIL_SIM_GOI', // BUTTON_KHOI_PHUC_DETAIL_SIM_GOI
  VIEW_COMMISSION_RATE: 'VIEW_COMMISSION_RATE', // XEM_TI_LE_HOA_HONG_SIM_GOI
  REQUEST_ACTIVE_SIM_GOI: 'REQUEST_ACTIVE_SIM_GOI', // BUTTON YEU_CAU_KICH_HOAT_SIM_GOI
  FILTER_ACTIVE_SIM_GOI: 'FILTER_ACTIVE_SIM_GOI', // BO_LOC_DON_KICH_HOAT_SIM_GOI
  FILTER_ACTIVE_SIM_GOI_BY_CREATER: 'FILTER_ACTIVE_SIM_GOI_BY_CREATER', // BO_LOC_DON_KICH_HOAT_SIM_GOI_THEO_NGUOI_TAO
  ASSIGNEE_DETAIL_ACTIVE_SIM_GOI: 'ASSIGNEE_DETAIL_ACTIVE_SIM_GOI', // ASSIGNEE_DETAIL_DON_KICH_HOAT_SIM_GOI
  REJECT_ACCEPT_BUTTON_DETAIL_ACTIVE: 'ACCEPT_BUTTON_DETAIL_ACTIVE', // TU_CHOI_DUYET_BUTTON_DETAIL_DON_KICH_HOAT_SIM_GOI
  OTHER_DOCUMENT_UPLOAD: 'OTHER_DOCUMENT_UPLOAD', // UPLOAD_TAI_LIEU_KHAC
  VIEW_OWNER_SIM_GOI: 'VIEW_OWNER_SIM_GOI', // XEM_CHU_SO_HUU_SIM_GOI

  // SIM_SO
  UPLOAD_LIST_SIM_SO: 'UPLOAD_LIST_SIM_SO', // UPLOAD_DANH_SACH_SIM
  APPROVE_LIST_UPLOAD_SIM_SO: 'APPROVE_LIST_UPLOAD_SIM_SO', // DANH_SACH_UPLOAD
  UPDATE_LIST_UPLOAD_SIM_SO: 'UPDATE_LIST_UPLOAD_SIM_SO', // PHE_DUYET_DANH_SACH_UPLOAD
  MANAGE_SIM_WAREHOUSE_SIM_SO: 'MANAGE_SIM_WAREHOUSE_SIM_SO', // QUAN_LY_KHO_SIM
  MANAGE_SIM_ACTIVATION_SIM_SO: 'MANAGE_SIM_ACTIVATION_SIM_SO', // QUAN_LY_KICH_HOAT_SIM
  EDIT_DETAIL_SIM_WAREHOUSE_SIM_SO: 'EDIT_DETAIL_SIM_WAREHOUSE_SIM_SO', // SUA_THONG_TIN_GOI_CUOC
  VISIBLE_SIM_SO: 'VISIBLE_SIM_SO',
  FILTER_SIM_SO: 'FILTER_SIM_SO', // BO_LOC_SIM_SO
  FILTER_BY_SELLER_SIM_SO: 'FILTER_BY_SELLER_SIM_SO', // LOC DANH SACH SIM GOI THEO SELLER

  // // SIM_SO detail
  RESTORE_LIST_SIM_SO: 'RESTORE_LIST_SIM_SO', // KHOI_PHUC_SIM_SO
  REMOVE_NUMBER_SIM_SO: 'REMOVE_NUMBER_SIM_SO', // GO_SO_SIM_SO
  EDIT_BUTTON_DETAIL_SIM_SO: 'EDIT_BUTTON_DETAIL_SIM_SO', // BUTTON_SUA_DETAIL_SIM_SO
  REMOVE_BUTTON_DETAIL_SIM_SO: 'REMOVE_BUTTON_DETAIL_SIM_SO', // BUTTON_GO_DETAIL_SIM_SO
  RESTORE_BUTTON_DETAIL_SIM_SO: 'RESTORE_BUTTON_DETAIL_SIM_SO', // BUTTON_KHOI_PHUC_DETAIL_SIM_SO
  VIEW_COMMISSION_RATE_SIM_SO: 'VIEW_COMMISSION_RATE_SIM_SO', // XEM_TI_LE_HOA_HONG_SIM_SO
  REQUEST_ACTIVE_SIM_SO: 'REQUEST_ACTIVE_SIM_SO', // YEU_CAU_KICH_HOAT_SIM_SO
  FILTER_ACTIVE_SIM_SO: 'FILTER_ACTIVE_SIM_SO', // BO_LOC_DON_KICH_HOAT_SIM_SO
  FILTER_ACTIVE_SIM_SO_BY_CREATER: 'FILTER_ACTIVE_SIM_SO_BY_CREATER', // BO_LOC_DON_KICH_HOAT_SIM_GOI_THEO_NGUOI_TAO
  FILTER_ACTIVE_SIM_SO_BY_SELLER: 'FILTER_ACTIVE_SIM_SO_BY_SELLER', // BO_LOC_DON_KICH_HOAT_SIM_SO_THEO_SELLER
  ASSIGNEE_DETAIL_ACTIVE_SIM_SO: 'ASSIGNEE_DETAIL_ACTIVE_SIM_SO', // ASSIGNEE_DETAIL_DON_KICH_HOAT_SIM_SO
  REJECT_ACCEPT_BUTTON_DETAIL_ACTIVE_SIM_SO: 'REJECT_ACCEPT_BUTTON_DETAIL_ACTIVE_SIM_SO', // TU_CHOI_DUYET_BUTTON_DETAIL_DON_KICH_HOAT_SIM_SO
  VIEW_OWNER_SIM_SO: 'VIEW_OWNER_SIM_SO', // XEM_CHU_SO_HUU_SIM_SO

  // SIM_M2M_&_DU_LICH
  UPLOAD_LIST_M2M_DU_LICH: 'UPLOAD_LIST_M2M_DU_LICH', // UPLOAD_DANH_SACH_SIM
  APPROVE_LIST_UPLOAD_M2M_DU_LICH: 'APPROVE_LIST_UPLOAD_M2M_DU_LICH', // XEM_DANH_SACH_UPLOAD
  UPDATE_LIST_UPLOAD_SIM_M2M_DU_LICH: 'UPDATE_LIST_UPLOAD_SIM_M2M_DU_LICH', // PHE_DUYET_DANH_SACH_UPLOAD
  MANAGE_SIM_WAREHOUSE_M2M_DU_LICH: 'MANAGE_SIM_WAREHOUSE_M2M_DU_LICH', // QUAN_LY_KHO_SIM
  MANAGE_SIM_ACTIVATION_M2M_DU_LICH: 'MANAGE_SIM_ACTIVATION_M2M_DU_LICH', // QUAN_LY_KICH_HOAT_SIM
  EDIT_DETAIL_SIM_WAREHOUSE_M2M_DU_LICH: 'EDIT_DETAIL_SIM_WAREHOUSE_M2M_DU_LICH', // SUA_THONG_TIN_GOI_CUOC
  VISIBLE_SIM_M2M_DU_LICH: 'VISIBLE_SIM_M2M_DU_LICH',
  VISIBLE_SIM_M2M: 'VISIBLE_SIM_M2M',
  FILTER_SIM_M2M_DU_LICH: 'FILTER_SIM_M2M_DU_LICH', // BO_LOC_SIM_M2M_DU_LICH
  FILTER_BY_SELLER_SIM_M2M_DU_LICH: 'FILTER_BY_SELLER_SIM_M2M_DU_LICH', // LOC DANH SACH SIM M2M DU  THEO SELLER
  // // SIM_M2M_&_DU_LICH detail
  RESTORE_LIST_SIM_M2M_DU_LICH: 'RESTORE_LIST_SIM_M2M_DU_LICH', // KHOI_PHUC_SIM_M2M_DU_LICH
  REMOVE_NUMBER_SIM_M2M_DU_LICH: 'REMOVE_NUMBER_SIM_M2M_DU_LICH', // GO_SO_SIM_M2M_DU_LICH
  EDIT_BUTTON_DETAIL_SIM_M2M_DU_LICH: 'EDIT_BUTTON_DETAIL_SIM_M2M_DU_LICH', // BUTTON_SUA_DETAIL_SIM_M2M_DU_LICH
  REMOVE_BUTTON_DETAIL_SIM_M2M_DU_LICH: 'REMOVE_BUTTON_DETAIL_SIM_M2M_DU_LICH', // BUTTON_GO_DETAIL_SIM_M2M_DU_LICH
  RESTORE_BUTTON_DETAIL_SIM_M2M_DU_LICH: 'RESTORE_BUTTON_DETAIL_SIM_M2M_DU_LICH', // BUTTON_KHOI_PHUC_DETAIL_SIM_M2M_DU_LICH
  VIEW_COMMISSION_RATE_SIM_M2M_DU_LICH: 'VIEW_COMMISSION_RATE_SIM_M2M_DU_LICH', // XEM_TI_LE_HOA_HONG_SIM_M2M_DU_LICH
  REQUEST_ACTIVE_SIM_M2M_DU_LICH: 'REQUEST_ACTIVE_SIM_M2M_DU_LICH', // BUTTON_YEU_CAU_KICH_HOAT_SIM_M2M_DU_LICH
  FILTER_ACTIVE_SIM_M2M_DU_LICH: 'FILTER_ACTIVE_SIM_M2M_DU_LICH', // BO_LOC_DON_KICH_HOAT_SIM_M2M_DU_LICH
  FILTER_ACTIVE_SIM_M2M_DU_LICH_BY_CREATER: 'FILTER_ACTIVE_SIM_M2M_DU_LICH_BY_CREATER', // BO_LOC_DON_KICH_HOAT_SIM_M2M_DU_LICH_THEO_NGUOI_TAO
  ASSIGNEE_DETAIL_ACTIVE_SIM_M2M_DU_LICH: 'ASSIGNEE_DETAIL_ACTIVE_SIM_M2M_DU_LICH', // ASSIGNEE_DETAIL_DON_KICH_HOAT_SIM_M2M_DU_LICH
  REJECT_ACCEPT_BUTTON_DETAIL_ACTIVE_SIM_M2M_DU_LICH: 'REJECT_ACCEPT_BUTTON_DETAIL_ACTIVE_SIM_M2M_DU_LICH', // TU_CHOI_DUYET_BUTTON_DETAIL_DON_KICH_HOAT_SIM_M2M_DU_LICH
  VIEW_OWNER_SIM_M2M_DU_LICH: 'VIEW_OWNER_SIM_M2M_DU_LICH', // XEM_CHU_SO_HUU_SIM_M2M_DU_LICH
  FILTER_BY_SELLER_ACTIVE_SIM_M2M_DU_LICH: 'FILTER_BY_SELLER_ACTIVE_SIM_M2M_DU_LICH', // BO_LOC_DON_KICH_HOAT_SIM_M2M_DU_LICH

  // QUAN_LY_GOI_CUOC
  GET_LIST_PACKAGED_SIM: 'GET_LIST_PACKAGED_SIM', // XEM_DANH_SACH_GOI_CUOC
  CREATE_PACKAGED_SIM: 'CREATE_PACKAGED_SIM', // THEM_GOI_CUOC
  UPDATE_PACKAGED_SIM: 'UPDATE_PACKAGED_SIM', // SUA_GOI_CUOC
  DELETE_PACKAGED_SIM: 'DELETE_PACKAGED_SIM', // XOA_GOI_CUOC
  GET_DETAIL_PACKAGED_SIM: 'GET_DETAIL_PACKAGED_SIM', // XEM_CHI_TIET_GOI_CUOC

  // QUAN_LY_WEBSITE
  VIEW_SETTING: 'VIEW_SETTING', // XEM_CAI_DAT
  VIEW_SETTING_DL_M2M_CHINA: 'VIEW_SETTING_DL_M2M_CHINA', // XEM_CAI_DAT
  VIEW_DETAIL: 'VIEW_DETAIL', // XEM_CAI_DAT
  EDIT_SETTING: 'EDIT_SETTING', // SUA_CAI_DAT
  CREATE_SETTING: 'CREATE_SETTING', // THEM_MOI_CAI_DAT

  // QUAN_LY_TAI_KHOAN
  VIEW_NAV_ACCOUNT: 'VIEW_NAV_ACCOUNT', // Nhìn thấy trên sidebar

  VIEW_LIST_INTERNAL_ACCOUNT: 'VIEW_LIST_INTERNAL_ACCOUNT', // XEM_DANH_SACH_TAI_KHOAN_NOI_BO
  VIEW_LIST_EXTERNAL_ACCOUNT: 'VIEW_LIST_EXTERNAL_ACCOUNT', // XEM_DANH_SACH_TAI_KHOAN_NGOAI
  VIEW_LIST_PARTNER_ACCOUNT: 'VIEW_LIST_PARTNER_ACCOUNT', // XEM_DANH_SACH_TAI_KHOAN_PARTNER

  UPDATE_STATUS_ACCOUNT: 'UPDATE_STATUS_ACCOUNT', // UPDATE_TRANG_THAI_TAI_KHOAN
  SEARCH_FILTER: 'SEARCH_FILTER', // SEARCH_FILTER
  VIEW_DETAIL_ACCOUNT: 'VIEW_DETAIL_ACCOUNT', // XEM_CHI_TIET_TAI_KHOAN
  EDIT_DETAIL_ACCOUNT: 'EDIT_DETAIL_ACCOUNT', // CHINH_SUA_TAI_KHOAN

  VIEW_DETAIL_PARTNER_ACCOUNT: 'VIEW_DETAIL_PARTNER_ACCOUNT', // XEM_CHI_TIET_TAI_KHOAN

  LIST_ACCOUNT_INTERNAL_LOWER_MANAGER: 'LIST_ACCOUNT_INTERNAL_LOWER_MANAGER', // TAI_KHOAN_NOI_BO_DUOI_QUEN_MANAGER
  LIST_ACCOUNT_INTERNAL_UPPER_MANAGER: 'LIST_ACCOUNT_INTERNAL_UPPER_MANAGER', // TAI_KHOAN_NOI_BO_TREN_QUEN_MANAGER
  CTV_ACCOUNT: 'CTV_ACCOUNT', // TAI_KHOAN_CTV
  DELETE_ACCOUNT: 'DELETE_ACCOUNT', // XOA_TAI_KHOAN
  EDIT_PARTNER_ACCOUNT: 'EDIT_PARTNER_ACCOUNT',
  EDIT_DETAIL_ACCOUNT_NHANVIEN: 'EDIT_DETAIL_ACCOUNT_NHANVIEN', // CHINH SUA TAI KHOAN CAP NHAN VIEN
  ACTIVE_DEACTIVE_EDIT_PARTNER: 'ACTIVE_DEACTIVE_EDIT_PARTNER',

  // QUAN_LY_TICKET
  VIEW_LIST_TICKET: 'VIEW_LIST_TICKET', // XEM_DANH_SACH_TICKET
  VIEW_DETAIL_TICKET: 'VIEW_DETAIL_TICKET', // XEM_CHI_TIET_TICKET
  UPDATE_STATUS_TICKET: 'UPDATE_STATUS_TICKET', // UPDATE_TRANG_THAI_TICKET

  // DON_HANG
  VIEW_LIST_ORDER: 'VIEW_LIST_ORDER', // XEM_DANH_SACH_DON_HANG
  FILTER_SEARCH_ORDER: 'FILTER_SEARCH_ORDER', // FILTER_SEARCH_DON_HANG
  VIEW_DETAIL_ORDER: 'VIEW_DETAIL_ORDER', // XEM_CHI_TIET_DON_HANG
  UPDATE_STATUS_ORDER: 'UPDATE_STATUS_ORDER', // UPDATE_TRANG_THAI_DON_HANG

  // QUAN_LY_SAN_PHAM
  VIEW_PRODUCT_PORTFOLIO: 'VIEW_PRODUCT_PORTFOLIO', // XEM_DANH_SACH_CHI_TIET_DANH_MUC_SAN_PHAM
  UPDATE_PRODUCT_PORTFOLIO: 'UPDATE_PRODUCT_PORTFOLIO', // CHINH_SUA_XOA_DANH_MUC_SAN_PHAM
  DELETE_PRODUCT_PORTFOLIO: 'DELETE_PRODUCT_PORTFOLIO', // XOA_DANH_MUC_SAN_PHAM
  VIEW_LIST_PRODUCT: 'VIEW_LIST_PRODUCT', // XEM_DANH_SACH_SAN_PHAM
  SEARCH_FILTER_PRODUCT: 'SEARCH_FILTER_PRODUCT', // SEARCH_FILTER_DANH_SACH_SAN_PHAM
  WARRANTY_ACTIVATION: 'WARRANTY_ACTIVATION', // KICH_HOAT_BAO_HANH
  VIEW_DETAIL_PRODUCT: 'VIEW_DETAIL_PRODUCT', // XEM_CHI_TIET_SAN_PHAM
  CREATE_UPDATE_PRODUCT: 'CREATE_UPDATE_PRODUCT', // TAO_MOI_CHINH_SUA_SAN_PHAM
  CREATE_IMPORT_REQUEST: 'CREATE_IMPORT_REQUEST', // TAO_DON_YEU_CAU_NHAP_KHO
  CREATE_EXPORT_REQUEST: 'CREATE_EXPORT_REQUEST', // TAO_DON_YEU_CAU_XUAT_KHO
  VIEW_IMPORT_REQUEST: 'VIEW_IMPORT_REQUEST',
  APPROVE_IMPORT_EXPORT_REQUEST: 'APPROVE_IMPORT_EXPORT_REQUEST', // PHE_DUYET_YEU_CAU_XUAT_NHAP_KHO
  CREATE_IMPORT_EXPORT_WARRANTY_MANAGEMENT_REQUEST: 'CREATE_IMPORT_EXPORT_WARRANTY_MANAGEMENT_REQUEST', // TAO_DON_YEU_CAU_XUAT_NHAP_QUAN_LY_BAO_HANH
  VIEW_WARRANTY_MANAGEMENT_REQUEST: 'VIEW_WARRANTY_MANAGEMENT_REQUEST', // XEM_DON_YEU_CAU_XUAT_NHAP_QUAN_LY_BAO_HANH
  APPROVE_IMPORT_EXPORT_WARRANTY_MANAGEMENT_REQUEST: 'APPROVE_IMPORT_EXPORT_WARRANTY_MANAGEMENT_REQUEST', // PHE_DUYET_YEU_CAU_XUAT_NHAP_QUAN_LY_BAO_HANH
  TOPUP_ORDERS_MANAGEMENT: 'TOPUP_ORDERS_MANAGEMENT', // XEM_DANH_SACH_DON_HANG_NAP_THE
  PROVIDER_ORDERS_MANAGEMENT: 'PROVIDER_ORDERS_MANAGEMENT', // XEM_DANH_SACH_DON_HANG_NHA_MANG

  // GIA HẠN GÓI CƯỚC SIM SỐ
  VIEW_EXTEND_ATTRACTIVE_SIM: 'VIEW_EXTEND_ATTRACTIVE_SIM', // XEM_DANH_SACH_GIA_HAN_GOI_CUOC_SIM_SO
  VIEW_DETAIL_EXTEND_SIM: 'VIEW_DETAIL_EXTEND_SIM', // XEM_CHI_TIET_GIA_HAN_GOI_CUOC_SIM_SO
  VIEW_ARCHIVED_EXTEND_ATTRACTIVE_SIM: 'VIEW_ARCHIVED_EXTEND_ATTRACTIVE_SIM', // XEM_DANH_SACH_GIA_HAN_GOI_CUOC_SIM_SO_DA_LUU_TRU
  CHANGE_CSKH_ASSIGNMENT: 'CHANGE_CSKH_ASSIGNMENT', // THAY_ĐỔI_CSKH
  CHANGE_TECHNICAL_ASSIGNMENT: 'CHANGE_TECHNICAL_ASSIGNMENT', // THAY_ĐỔI_KỸ_THUẬT

  // UPLOAD TEMPLATE SIM
  UPLOAD_TEMPLATE_SIM: 'UPLOAD_TEMPLATE_SIM', // UPLOAD_TEMPLATE_SIM

  // THIET LAP CHUNG
  DISCOUNT_CARD_MANAGEMENT: 'DISCOUNT_CARD_MANAGEMENT',

  // XUAT BAO CAO SIM
  EXPORT_REPORT_SIM: 'EXPORT_REPORT_SIM',

  // HOAN TIEN
  REFUND_MONEY_CARD: 'REFUND_MONEY_CARD',

  WHITE_LIST_SETTING: 'WHITE_LIST_SETTING',

  // BLOG
  VIEW_NAVIGATE_BLOG: 'VIEW_NAVIGATE_BLOG', // THẤY NAVIGATION BLOG
  VIEW_LIST_BLOG: 'VIEW_LIST_BLOG', // XEM_DANH_SACH_BLOG
  ACTION_LIST_BLOG: 'ACTION_LIST_BLOG', // THAO TAC TREN TRANG DANH SACH
  ACTION_DETAIL_BLOG: 'ACTION_DETAIL_BLOG', // THAO TAC TREN TRANG CHI TIET
  DELETE_BLOG_OTHER_USER: 'DELETE_BLOG_OTHER_USER', // XOA BLOG NGUOI KHAC
  EDIT_BLOG_OTHER_USER: 'EDIT_BLOG_OTHER_USER', // SUA BLOG NGUOI KHAC
  EDIT_BLOG_OWN: 'EDIT_BLOG_OWN', // SUA BLOG CUA MINH
  DELETE_BLOG_OWN: 'DELETE_BLOG_OWN', // XOA BLOG CUA MINH

  // XUAT BAO CAO THE NAP
  EXPORT_CARD: 'EXPORT_CARD',

  // XUAT BAO CAO GIA HAN
  EXPORT_RENEWALS: 'EXPORT_RENEWALS',

  // DOI SOAT
  CROSS_CHECK: 'CROSS_CHECK',

  // FLASH_SALE
  FLASH_SALE: 'FLASH_SALE',
}

// Admin
const ADMIN = {
  ...PERMISSION,
}

// staff chung
const STAFF = {
  ...PERMISSION,

}

// cộng tác viên
const CTV = {
  ...STAFF
}

// MKT
const MKT = {
  ...STAFF
}

export const PERMISSION_ROLE = [
  {
    role: ROLE.SUPER_ADMIN,
    permission: PERMISSION,
  },
  {
    role: ROLE.ADMIN,
    permission: ADMIN,
  },
  {
    role: ROLE.CTV,
    permission: CTV,
  },
  {
    role: ROLE.MKT,
    permission: MKT,
  },
]
