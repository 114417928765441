/* eslint-disable consistent-return */
/* eslint-disable no-restricted-properties */
/* eslint-disable arrow-body-style */
import html2canvas from 'html2canvas'
import ReactDOM from 'react-dom'
import moment from 'moment'
import { CONVERT_PRODUCT_STATUS, UPLOAD_BLOCK_ITEMS_SIZE } from '../constants/config'
import { get } from './localStorage'
import { MY_ROLES } from '../constants/constant'
import AccessoryApis from '../apis/accessoryApis'

/* eslint-disable no-bitwise */
export async function wait(second) {
  return new Promise((accept) => {
    setTimeout(() => {
      accept()
    }, second * 1000)
  })
}

export function parseDate(dateString) {
  const parts = dateString.split('/')
  const day = parseInt(parts[1], 10)
  const month = parseInt(parts[0], 10)
  let year = parseInt(parts[2], 10)
  if (year >= 0 && year <= 49) {
    year += 2000
  } else {
    year += 1900
  } return new Date(`${year}`, month - 1, day)
}

export function convertToNumber(str) {
  const replacedStr = str.replace(/[,.]/g, '')
  const number = Number(replacedStr)
  return number
}

export function addLeadingZero(str) {
  if (!str) return ''
  const tmpStr = str.toString()
  // Nếu tách số, không có 84 ở đầu, kí tự đầu khác 0 và có dấu chấm thì thêm 0
  if (`${tmpStr.replace(/\./g, '')}`.length === 9 && `${str}`.charAt(0) !== '0' && `${str}`.includes('.')) {
    return `0${str}`
  }

  // nếu là số, không có 84 ở đầu, kí tự đầu khác 0 và không có dấu chấm thì thêm 0
  if (`${str}`.length === 9 && `${str}`.charAt(0) !== '0' && !`${str}`.includes('.')) {
    return `0${str}`
  }

  // nếu là số, có 84 ở đầu, không có dấu chám thì thay 84 thành 0
  if (`${str}`.length === 11 && `${str}`.charAt(0) === '8' && `${str}`.charAt(1) === '4' && !`${str}`.includes('.')) {
    return `0${str.toString().slice(2)}`
  }

  // nếu là tách số, có 84 ở đầu, kí tự đầu khác 0 và có dấu chấm thì thay 84 thành 0
  if (`${tmpStr.replace(/\./g, '')}`.length === 11 && `${str}`.charAt(0) === '8' && `${str}`.charAt(1) === '4' && `${str}`.includes('.')) {
    return `0${str.slice(2)}`
  }

  return `${str}`
}

export function getProviderName(str) {
  if (`${str}`.toLowerCase() === 'vinaphone') {
    return 'Vinaphone'
  }
  if (`${str}`.toLowerCase() === 'viettel') {
    return 'Viettel'
  }
  if (`${str}`.toLowerCase() === 'wintel') {
    return 'Wintel'
  }
  if (`${str}`.toLowerCase() === 'mobifone') {
    return 'Mobifone'
  }

  return ''
}

export function getProviderId(providers, providerName) {
  const provider = providers.find(
    (x) => x.name?.toLowerCase() === providerName?.toLowerCase(),
  )
  if (provider) return provider.id
  return ''
}

export function formatNumber(number, n, x) {
  const re = `\\d(?=(\\d{${x || 3}})+${n > 0 ? '\\.' : '$'})`
  try {
    return number.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,')
  } catch (e) {
    return number
  }
}

export const formatNumberWithSuffix = (number) => {
  if (number === 0) {
    return '0'
  }
  try {
    const suffixes = ['', 'nghìn', 'triệu', 'tỷ', 'nghìn tỷ']

    // Determine the appropriate suffix based on the number of digits
    const suffixIndex = Math.floor(Math.log10(number) / 3)
    const suffix = suffixes[suffixIndex]

    // Calculate the adjusted number to display
    const adjustedNumber = number / Math.pow(10, suffixIndex * 3)

    // Use Intl.NumberFormat to format the number with commas
    const formattedNumber = new Intl.NumberFormat().format(adjustedNumber)

    // Concatenate the formatted number and suffix
    const result = `${formattedNumber} ${suffix}`

    return result ? result.trim() : 0
  } catch (e) {
    return number
  }
}

export const phoneNumberFrom084Converter = (phoneNumber) => {
  try {
    return `0${phoneNumber.slice(2)}`
  } catch (e) {
    return phoneNumber
  }
}

export function replaceAllCommasAndPeriods(str, replacement) {
  // Sử dụng biểu thức chính quy để tìm tất cả các dấu "," và "."
  const regex = /[,.]/g

  // Thay thế tất cả các dấu "," và "." bằng ký tự thay thế
  const result = str.replace(regex, replacement)

  return result
}

export function stringToPrice(str) {
  const regex = /[,]/g

  const result = str.replace(regex, '')

  return parseFloat(result)
}

export function padZero(number) {
  if (!Number.isNaN(number) && parseInt(number, 10) < 10) {
    return `0${number}`
  }
  return number.toString()
}

export const getBlockSize = (lengthArray) => {
  let blockSize = UPLOAD_BLOCK_ITEMS_SIZE
  if (lengthArray < 1000) {
    blockSize = Math.ceil(lengthArray / 3)
  } else if (lengthArray < 2000) {
    blockSize = Math.ceil(lengthArray / 5)
  } else if (lengthArray < 3000) {
    blockSize = Math.ceil(lengthArray / 6)
  } else if (lengthArray < 4000) {
    blockSize = Math.ceil(lengthArray / 7)
  } else if (lengthArray < 6000) {
    blockSize = Math.ceil(lengthArray / 8)
  }
  return blockSize
  // return 1
}

export const splitBlockArray = (array, blockSize) => {
  const blocks = []
  for (let i = 0; i < array.length; i += blockSize) {
    const block = array.slice(i, i + blockSize)
    blocks.push(block)
  }
  return blocks
}

export const generateProviderImg = (provider) => {
  switch (provider.toLocaleLowerCase()) {
    case 'mobifone':
      return '/images/common/sim-mobiphone.png'
    case 'vinaphone':
      return '/images/common/sim-vinaphone.png'
    case 'vietnamobile':
      return '/images/common/sim-vietnam-mobile.png'
    case 'wintel':
      return '/images/common/sim-wintel.png'
    default:
      return '/images/common/sim-viettel.png'
  }
}

const DEFAULT_PNG = {
  fileName: 'image.png',
  type: 'image/png',
  html2CanvasOptions: {},
}

const saveAs = (uri, filename) => {
  const link = document.createElement('a')
  if (typeof link.download === 'string') {
    link.href = uri
    link.download = filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  } else {
    window.open(uri)
  }
}

const exportComponent = async (
  node,
  { fileName, type, html2CanvasOptions },
) => {
  // eslint-disable-next-line react/no-find-dom-node
  const element = ReactDOM.findDOMNode(node)
  return html2canvas(element, {
    scrollY: -window.scrollY,
    useCORS: true,
    ...html2CanvasOptions,
  }).then((canvas) => {
    saveAs(canvas.toDataURL(type, 1.0), fileName)
  })
}

export const exportComponentAsPNG = async (node, parameters = {}) => {
  await exportComponent(node, { ...DEFAULT_PNG, ...parameters })
}

export const roleExists = (inputRoles) => {
  if (typeof inputRoles === 'string') {
    return (get(MY_ROLES) || []).includes(inputRoles)
  }
  if (Array.isArray(inputRoles)) {
    return inputRoles.some((role) => (get(MY_ROLES) || []).includes(role))
  }
  return false
}

export const getProviderImageUrl = (provider_name) => {
  return `/images/common/sim-${provider_name?.toLowerCase?.()}.png`
}
export const getSimDLProviderImageUrl = (provider_name) => {
  return `/images/sim/sim-dl-${provider_name?.toLowerCase?.()}.svg`
}
export const getSimM2MProviderImageUrl = (provider_name) => {
  return `/images/sim/sim-m2m-${provider_name?.toLowerCase?.()}_v2.svg`
}

export const getActionTextSim = (status) => {
  if (status === 'unavailable') {
    return 'Khôi phục'
  }
  return 'Gỡ số'
}

export const removeNullAttributes = (obj) => Object.keys(obj).reduce((acc, key) => {
  if (obj[key] !== null) {
    acc[key] = obj[key]
  }
  return acc
}, {})

export const isMobileDevice = () => {
  return window.innerWidth <= 1023
}

export const disableScroll = () => {
  document.body.style.overflow = 'hidden'
  document.body.style.height = '100vh'
}

export const enableScroll = () => {
  document.body.style.overflow = 'unset'
  document.body.style.height = 'unset'
}

export const CONVERT_PRODUCT_STATUS_ENGLISH = [{
  vi: 'Không có sẵn',
  en: 'unavailable',
},
{
  vi: 'Có sẵn',
  en: 'available',

},
{
  vi: 'Đang đặt hàng',
  en: 'ordering',
},
{
  vi: 'Đã bán',
  en: 'sold',
},

]

export const checkPackageAvailable = (packageData) => packageData?.items?.some((item) => item?.status === 'available')

export function convertTimeString(str) {
  try {
    const timeString = str

    // Phân tách ngày và giờ từ chuỗi
    const [dateString, time] = timeString.split(' - ')
    const [day, month, year] = dateString.split('/')

    // Tạo đối tượng Date từ ngày và giờ
    const dateObject = new Date(`${year}-${month}-${day}T${time}:00`)

    const newMomentObject = moment(dateObject).add(7, 'hours')

    // Định dạng lại chuỗi thời gian mới
    const formattedTime = newMomentObject.format('DD/MM/YYYY - HH:mm')

    return formattedTime
  } catch {
    return str
  }
}

export function convertTimeStringToDeadLine(str) {
  try {
    const timeString = str

    // Phân tách ngày và giờ từ chuỗi
    const [dateString, time] = timeString.split(' - ')
    const [day, month, year] = dateString.split('/')

    // Tạo đối tượng Date từ ngày và giờ
    const dateObject = new Date(`${year}-${month}-${day}T${time}:00`)

    const newMomentObject = moment(dateObject).add(7, 'hours').add(1, 'days')

    // Định dạng lại chuỗi thời gian mới
    const formattedTime = newMomentObject.format('DD/MM/YYYY - HH:mm')

    return formattedTime
  } catch {
    return str
  }
}

export const uploadFile = async (uploadedFile) => {
  const formData = new FormData()
  formData.append('file', uploadedFile)
  const res = await AccessoryApis.uploadFile(formData)
  return res?.data?.data
}

export const convertKeyTemplateSim = {
  simso: 'number_sim_template_url',
  simgoi: 'package_sim_template_url',
  simDL: 'tourist_sim_template_url',
  simM2M: 'tourist_sim_template_url',
//   Sim M2M và Sim DL dùng chung 1 template
}

export function formatLargeNumber(number) {
  const billion = 1000000000
  const million = 1000000
  let rs = number.toString()
  if (Math.abs(number) >= billion) {
    rs = `${(number / billion).toLocaleString('en-US', { maximumFractionDigits: 3 })} tỷ`
  } else if (Math.abs(number) >= million) {
    rs = `${(number / million).toLocaleString('en-US', { maximumFractionDigits: 3 })} triệu`
  } else if (number < million) {
    rs = `${(number).toLocaleString('en-US', { maximumFractionDigits: 3 })}`
  }
  rs = rs.replace(/\./g, '#').replace(/,/g, '.').replace(/#/g, ',')
  return rs
}

export const getTextTagAccount = {
  active: 'Hoạt động',
  suspend: 'Tạm ngừng',
  rejected: 'Từ chối',
}

export const getTextTagExchangePoint = {
  pending: 'Chờ duyệt',
  success: 'Thành công',
  cancel: 'Từ chối',
  rejected: 'Từ chối',
}

export function suffixLanguage(language) {
  if (language === 'en') {
    return '$'
  }
  if (language === 'zh') {
    return '¥'
  }
  return 'VND'
}

export function formatDateNoti(notiDateStr) {
  try {
    const notiDate = new Date(notiDateStr)
    const currentDate = new Date()

    const notiDateUTC = Date.UTC(notiDate.getFullYear(), notiDate.getMonth(), notiDate.getDate())
    const currentUTC = Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())

    const diffTime = Math.abs(currentUTC - notiDateUTC)

    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24))

    const hours = notiDate.getHours().toString().padStart(2, '0')
    const minutes = notiDate.getMinutes().toString().padStart(2, '0')

    if (diffDays === 0) {
      return `Hôm nay ${hours}:${minutes}`
    } if (diffDays === 1) {
      return `Hôm qua ${hours}:${minutes}`
    }
    const day = notiDate.getDate().toString().padStart(2, '0')
    const month = (notiDate.getMonth() + 1).toString().padStart(2, '0')
    const year = notiDate.getFullYear()
    return `${day}/${month}/${year} ${hours}:${minutes}`
  } catch (error) {
    console.error('Error formatting notification date:', error)
    // Nếu có lỗi, trả về định dạng mặc định
    return moment(notiDateStr).format('DD-MM-YYYY hh:mm')
  }
}
