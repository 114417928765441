/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { set, get, KEYS } from '../../util/localStorage'
import {
  PERMISSION_ROLE, ROLE_INTERNAL_DIAGRAM,
} from '../../constants/permission'

export const handlePermission = (accountRole = null) => {
  let roles = null
  if (accountRole) {
    roles = accountRole
  } else {
    roles = get(KEYS.MY_ROLES)
  }
  if (roles) {
    const user_permission = []

    roles.forEach((role) => {
      const permission = PERMISSION_ROLE.find((x) => x.role === role)
      if (permission) {
        user_permission.push({
          permission: permission?.permission,
          role,
        })
      }
    })

    return user_permission
  }
  return null
}

const permission = createSlice({
  name: 'permission',
  initialState: {
    value: handlePermission(),
  },
  reducers: {
    setPermission: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    resetPermission: (state) => {
      state.value = null
    },
  },
})

export const { setPermission, resetPermission } = permission.actions

export const getPermission = (state) => state.permission.value

// permissionValue truyền vào là 1 quyền ví dụ PERMISSION.CHANGE_PASSWORD
// userPermission truyền vào là 1 list role được mapping với permission chính là return user_permission trong function handlePermission
export const isHasPermission = (permissionValues, userPermission) => {
  return true
  if (!userPermission) {
    userPermission = handlePermission()
  }

  if (!Array.isArray(permissionValues)) {
    permissionValues = [permissionValues]
  }

  let hasPermission = false

  if (userPermission) {
    userPermission.forEach((item) => {
      const permissionRole = PERMISSION_ROLE.find((x) => x.role === item.role)

      if (permissionRole) {
        const hasRequiredPermission = permissionValues.some((permissionValue) => Object.values(permissionRole.permission).includes(permissionValue))

        if (hasRequiredPermission) {
          hasPermission = true
        }
      }
    })
  }

  return hasPermission
}

export const isHasRole = (role, userPermission) => {
  if (!userPermission) {
    userPermission = handlePermission()
  }
  let isExist = false
  if (userPermission) {
    userPermission.forEach((item) => {
      if (item.role === role) {
        isExist = true
      }
    })
  }

  return isExist
}

export const getPermisionByRole = (role, userPermission) => {
  let permissionItem = null
  if (!userPermission) {
    userPermission = handlePermission()
  }
  if (userPermission) {
    userPermission.forEach((item) => {
      if (item.role === role) {
        permissionItem = item
      }
    })
  }

  return permissionItem
}

export const getPermisionByListRole = (listRole, userPermission) => {
  let isExist = false
  if (!userPermission) {
    userPermission = handlePermission()
  }
  try {
    if (userPermission) {
      listRole.forEach((role) => {
        const user = userPermission.find((x) => x.role === role)
        if (user) {
          isExist = true
        }
      })
    }
  } catch (e) {
    return false
  }

  return isExist
}

const findChidrenDiagram = (role, diagram = ROLE_INTERNAL_DIAGRAM) => {
  let data = null
  diagram.children?.forEach((x) => {
    if (x.role === role) {
      data = x
    } else {
      if (data) {
        return
      }
      const recursive = findChidrenDiagram(role, x)
      if (recursive) {
        data = recursive
      }
    }
  })

  return data
}

const getChildrenRolesByParentRole = (diagram = ROLE_INTERNAL_DIAGRAM) => {
  let result = []
  if (diagram.children) {
    diagram.children.forEach((x) => {
      result.push(x.role)
      if (x.children) {
        const recursive = getChildrenRolesByParentRole(x)
        result = result.concat(recursive)
      }
    })
  }
  return result
}

const getChildrenRolesByLevel = (level, diagram = ROLE_INTERNAL_DIAGRAM) => {
  let result = []
  if (diagram.children) {
    diagram.children.forEach((x) => {
      if (x.level > level) {
        result.push(x.role)
      }
      if (x.children) {
        const recursive = getChildrenRolesByLevel(level, x)
        result = result.concat(recursive)
      }
    })
  }
  return result
}

export const getListRoleByRole = (roles) => {
  let level = 999
  roles.forEach((x) => {
    const diagram = findChidrenDiagram(x.role)
    if (diagram && level > diagram.level) {
      level = diagram.level
    }
  })

  const listRoles = getChildrenRolesByLevel(level)
  return listRoles
}

export default permission.reducer
