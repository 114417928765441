import Axios from '../services/Axios'

export function signInApi(username, password) {
  return Axios.getInstance().post('/v1/auth/login', {
    username,
    password,
  })
}

export function getProfile() {
  return Axios.getInstance().get('/v1/auth/my-profile')
}

export function resetPassword(data) {
  return Axios.getInstance().put('/v1/accounts/reset-password', data)
}

export function resetPasswordPartnerSeller(data) {
  return Axios.getInstance().put('/v1/partners/reset-password/sellers', data)
}
