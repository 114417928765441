import Axios from '../services/Axios'

export function uploadImageApi(formData) {
  return Axios.getInstance().post('/v1/upload-image', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

// export function uploadImageWithoutLogoApi(formData) {
//   return Axios.getInstance().post('/v1/upload-image-without-logo', formData, {
//     headers: {
//       'Content-Type': 'multipart/form-data',
//     },
//   })
// }

export function uploadImageWithoutLogoApi(formData) {
  return Axios.getInstance().post('/v1/upload-image', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export function uploadExcelFileApi(formData) {
  return Axios.getInstance().post('/v1/upload-excel-file', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export function uploadAllFileTypeoApi(formData) {
  return Axios.getInstance().post('/v1/upload-any-file', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
