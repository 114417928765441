import Axios from '../services/Axios'

export function listPartner(params) {
  return Axios.getInstance().get('/v1/partners', { params })
}

export function addPartner(data) {
  return Axios.getInstance().post('/v1/partners', data)
}

export function listSellers(representative_id, params) {
  return Axios.getInstance().get(`/v1/partners/${representative_id}/sellers`, { params })
}

export function createSellerApi(data) {
  return Axios.getInstance().post('/v1/partners/sellers', data)
}

export function deleteSellerApi(data) {
  return Axios.getInstance().delete('/v1/partners/sellers', { data })
}

export function detailPartnerApi(representative_id) {
  return Axios.getInstance().get(`/v1/partners/${representative_id}`)
}

export function editPartnerApi(representative_id, data) {
  return Axios.getInstance().put(`/v1/partners/${representative_id}`, data)
}

export function getDetailSellerApi(id) {
  return Axios.getInstance().get(`/v1/partners/sellers/${id}`)
}

export function editSellerApi(id, params) {
  return Axios.getInstance().put(`/v1/partners/sellers/${id}`, params)
}

export function deactivateSeller(id, params) {
  return Axios.getInstance().put(`/v1/partners/deactivate/seller/${id}`, params)
}

export function activateSeller(id) {
  return Axios.getInstance().put(`/v1/partners/activate/seller/${id}`)
}

export function deactivatePartnerApi(representative_id, params) {
  return Axios.getInstance().put(`/v1/partners/deactivate/${representative_id}`, params)
}

export function activatePartnerApi(representative_id) {
  return Axios.getInstance().put(`/v1/partners/activate/${representative_id}`)
}
