import { CaretRightOutlined, SendOutlined } from "@ant-design/icons";
import {
  Button,
  Collapse,
  DatePicker,
  Drawer,
  Dropdown,
  Form,
  Input,
  message,
  Modal,
  Select,
  Spin,
  Tabs,
} from "antd";
import dayjs from "dayjs";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import InternalAccountApis from "../../../apis/internalAccountApis";
import {
  ACADEMIC_LEVEL,
  DEFAULT_IMAGE,
  regexPhoneNumber,
} from "../../../constants/constant";
import UploadImageComponent from "../../../components/common/uploadImage";
import { getErrorMessagesAccountPage } from "../../../util/errorMappingAccount";
import { roleExists } from "../../../util";
import {
  handlePermission,
  isHasPermission,
  isHasRole,
} from "../../../redux/reducers/permission";
import { PERMISSION, ROLE } from "../../../constants/permission";
import { resetPassword } from "../../../apis/authenticationApis";
import { KEYS, remove } from "../../../util/localStorage";

const getTextTag = {
  suspend: "Tạm ngừng",
  active: "Hoạt động",
  rejected: "Từ chối",
};

const TEXT = {
  CONFIRM_RESET_PASSWORD_TITLE: "Bạn muốn thiết lập lại mật khẩu?",
  RESET_PASSWORD_SUCCESS_TITLE: "Mật khẩu đã thiết lập lại thành công",
  CONFIRM_RESET_PASSWORD_CONTENT:
    "<p>Mật khẩu của tài khoản được chọn sẽ được thiết lập lại về mật khẩu mặc định</p>",
  RESET_PASSWORD_SUCCESS_CONTENT:
    "<div>Tên tài khoản: <b>{{username}}</b></div><div>Mật khẩu: <b>Password@123</b></div>",
};

function DetailAccountDrawer(props) {
  const {
    isOpenDetailDrawer,
    setIsOpenDetailDrawer,
    departmentList,
    selectedAccount,
    getListInternalAccounts,
    getDetailAccountFunction,
    isProfile,
    providerList,
  } = props;

  const [detailForm] = Form.useForm();
  const [dataDetail, setDataDetail] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState(DEFAULT_IMAGE);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [note, setNote] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [roleList, setRoleList] = useState([]);
  const [isOpenConfirmModalResetPassword, setIsOpenConfirmModalResetPassword] =
    useState(false);
  const [isResetPassword, setResetPassword] = useState(false);
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);

  const [isTechnicalAccount, setIsTechnicalAccount] = useState(false);

  const handleCloseDrawerDetail = () => {
    setIsOpenDetailDrawer(false);
    setRoleList([]);
    setSelectedDepartment(null);
    setIsTechnicalAccount(false);
    // fix flick title when close drawer
    setTimeout(() => {
      setIsEditing(false);
    }, 100);
  };

  const getRoleList = async () => {
    try {
      const res = await InternalAccountApis.getRoleList({
        department_ids: selectedDepartment,
      });
      setRoleList(res?.data?.data);
    } catch (error) {
      // message.error('Lấy chức vụ bị lỗi')
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedDepartment) {
      getRoleList();
    }
  }, [selectedDepartment]);

  useEffect(() => {
    if (avatarUrl) {
      detailForm.setFieldValue("avatar", avatarUrl);
    }
  }, [avatarUrl]);

  const getDetailAccount = async () => {
    setIsLoading(true);
    try {
      const detailAccount = await getDetailAccountFunction();
      setDataDetail(detailAccount);
    } catch (error) {
      message.error("Bạn không có quyền truy cập tính năng này!");
      handleCloseDrawerDetail();
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (isOpenDetailDrawer) {
      getDetailAccount();
    }
  }, [isOpenDetailDrawer]);

  const handleChangeStatus = async () => {
    try {
      if (dataDetail?.status !== "active") {
        await InternalAccountApis.activateAccount(dataDetail?.id);
      } else {
        await InternalAccountApis.deactivateAccount(dataDetail?.id, { note });
      }
      const newDataDetail = {
        ...dataDetail,
        status: dataDetail?.status === "active" ? "suspend" : "active",
        note,
      };
      setDataDetail(newDataDetail);
      getListInternalAccounts();
      setNote("");
      message.success("Thay đổi trạng thái thành công");
    } catch (error) {
      message.error("Thay đổi trạng thái thất bại");
    }
    setIsOpenConfirmModal(false);
  };

  const mappingDataForm = () => {
    const mappingData = {
      ...dataDetail,
      dob: dayjs(dataDetail?.dob),
      joined_at: dataDetail?.joined_at ? dayjs(dataDetail?.joined_at) : "",
      created_at: dayjs(dataDetail?.created_at),
      deparment:
        dataDetail?.roles?.[0]?.department_id ||
        dataDetail?.roles?.department_id,
      role_id: dataDetail?.roles?.[0]?.name || dataDetail?.roles?.name,
      creator_full_name:
        dataDetail?.creator?.full_name ||
        `${dataDetail?.creator?.first_name || ""} ${
          dataDetail?.creator?.last_name || ""
        }`,
      provider_ids: dataDetail?.provider_ids || [],
    };
    setAvatarUrl(dataDetail?.avatar || DEFAULT_IMAGE);
    setSelectedDepartment(mappingData?.deparment);
    detailForm.setFieldsValue(mappingData);
  };

  useEffect(() => {
    if (dataDetail) {
      mappingDataForm();
    }
  }, [dataDetail]);

  const handleResetPassword = () => {
    setIsOpenConfirmModalResetPassword(true);
  };

  const handleSubmit = async () => {
    setResetPassword(true);
    const user = dataDetail;

    try {
      const data = await resetPassword({
        username: `${user?.username}`,
      });

      if (data?.data?.data) {
        Modal.success({
          title: (
            <div
              style={{ fontSize: 20 }}
              dangerouslySetInnerHTML={{
                __html: TEXT.RESET_PASSWORD_SUCCESS_TITLE,
              }}
            />
          ),
          content: (
            <div
              dangerouslySetInnerHTML={{
                __html: TEXT.RESET_PASSWORD_SUCCESS_CONTENT.replace(
                  "{{username}}",
                  user?.username
                ),
              }}
            />
          ),
          width: 456,
          icon: (
            <img
              src="/images/common/check_circle.svg"
              alt="check_circle"
              width={48}
              height={48}
              style={{ marginRight: 16 }}
            />
          ),
          footer: (
            <div style={{ textAlign: "right" }}>
              <Button
                className="btn-confirm"
                onClick={() => {
                  Modal.destroyAll();
                  remove(KEYS.MY_ROLES);
                  remove(KEYS.USER);
                  remove(KEYS.TOKEN);
                  window.location.reload();
                }}
              >
                Ok
              </Button>
            </div>
          ),
        });
      } else {
        message.error("Không tìm thấy thông tin tài khoản");
      }
    } catch (e) {
      message.error("Không tìm thấy thông tin tài khoản");
    } finally {
      setIsOpenConfirmModalResetPassword(false);
      setResetPassword(false);
    }
  };

  const handleCancel = () => {
    setIsOpenConfirmModalResetPassword(false);
  };

  const items = [
    {
      label: (
        <div
          className="btn-dropdown"
          role="button"
          tabIndex={0}
          onClick={() => {
            setIsEditing(true);
          }}
        >
          <img
            width={24}
            height={24}
            alt="edit"
            src="/images/common/edit.svg"
          />
          Sửa thông tin
        </div>
      ),
      key: "0",
      disabled: false,
    },
    {
      label: (
        <div
          className="btn-dropdown"
          role="presentation"
          onClick={handleResetPassword}
        >
          <img
            width={24}
            height={24}
            alt="reset"
            src="/images/common/reset.svg"
          />
          Thiết lập lại mật khẩu
        </div>
      ),
      key: "1",
    },
    isHasPermission(PERMISSION.DELETE_ACCOUNT) && {
      label: (
        <div
          className="btn-dropdown text-red"
          role="presentation"
          onClick={() => {
            setIsOpenModalDelete(true);
          }}
        >
          <img
            width={24}
            height={24}
            alt="edit"
            src="/images/common/recycle_red_fill.svg"
          />
          Xóa tài khoản
        </div>
      ),
      key: "2",
    },
  ];

  const handleDeleteAccount = async () => {
    try {
      await InternalAccountApis.deleteAccount(selectedAccount?.id);
      setIsOpenModalDelete(false);
      setIsOpenDetailDrawer(false);
      getListInternalAccounts();
      message.success("Xóa tài khoản thành công");
    } catch {
      message.error("Xóa tài khoản thất bại");
    }
  };
  const extraAction = () => {
    if (dataDetail?.status === "active" && !isEditing) {
      return (
        <div className="drawer-extra">
          {/* <Button
            className="detail-modal-btn" onClick={() => {
              setIsEditing(true)
            }}
          >
            Chỉnh sửa
          </Button> */}
          <Button
            className="detail-modal-btn detail-modal-btn-suspend"
            onClick={() => setIsOpenConfirmModal(true)}
          >
            Vô hiệu hoá
          </Button>
          <Dropdown
            menu={{
              items,
            }}
            trigger={["click"]}
            overlayClassName="sim-dropdown"
          >
            <img
              alt="show-more"
              width={40}
              height={40}
              src="/images/common/show-more.svg"
            />
          </Dropdown>
        </div>
      );
    }

    if (dataDetail?.status === "suspend" && !isEditing) {
      return (
        <div className="drawer-extra">
          <Button
            className="detail-modal-btn detail-modal-btn-active"
            onClick={() => setIsOpenConfirmModal(true)}
          >
            Kích hoạt
          </Button>
        </div>
      );
    }

    return null;
  };

  const handleCloseModalConfirm = () => {
    setIsOpenConfirmModal(false);
  };

  const updateAccount = async () => {
    try {
      const newData = detailForm.getFieldsValue();

      // Nếu không thay đổi username thì xóa trước khi update
      if (newData?.username === dataDetail?.username) {
        delete newData.username;
      }

      // NẾu không thay đổi role_id thì xóa trước khi update
      if (
        newData?.role_id === dataDetail?.roles?.[0]?.name ||
        newData?.role_id === dataDetail?.roles?.[0]?.id
      ) {
        delete newData.role_id;
      }

      await InternalAccountApis.updateAccount(selectedAccount?.id, {
        ...newData,
        avatar:
          newData?.avatar && newData?.avatar !== "default avatar url"
            ? newData?.avatar
            : "https://statics-dev.simplus.space/image/64390d62-98a0-4c92-b9a5-d011c8671831.jpeg",
        provider_ids: newData?.provider_ids,
      });
      // handleCloseDrawerDetail()
      message.success("Cập nhật tài khoản thành công");
    } catch (error) {
      message.error(
        getErrorMessagesAccountPage(error?.response?.data?.message) ||
          "Cập nhật tài khoản thất bại"
      );
    }
  };

  const handleUpdateAccount = async () => {
    await updateAccount();
    getListInternalAccounts();
  };

  const handleChangeRole = (value) => {
    const role = roleList.find((item) => item.id === value);
    setIsTechnicalAccount(false);
  };

  return (
    <>
      <Drawer
        open={isOpenDetailDrawer}
        onClose={handleCloseDrawerDetail}
        className="drawer-common account-manage-drawer"
        title={isEditing ? "Chỉnh sửa tài khoản" : "Chi tiết tài khoản"}
        width={800}
        extra={
          isEditing && (
            <div className="drawer-extra">
              <Button
                onClick={() => {
                  setIsEditing(false);
                  mappingDataForm();
                }}
              >
                Hủy
              </Button>
              <Button
                type="primary"
                icon={<SendOutlined />}
                size="large"
                onClick={() => {
                  detailForm.submit();
                }}
              >
                Lưu
              </Button>
            </div>
          )
        }
      >
        {isLoading ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin />
          </div>
        ) : (
          <Tabs
            defaultActiveKey="1"
            tabBarExtraContent={!isProfile ? extraAction() : null}
          >
            <Tabs.TabPane tab="Tổng quan" key="1">
              <Form
                layout="vertical"
                disabled={!isEditing}
                form={detailForm}
                onFinish={handleUpdateAccount}
              >
                <Form.Item name="avatar">
                  <div
                    className="account-avatar"
                    style={{
                      height: "160px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <UploadImageComponent
                      withoutLogo
                      imageUrl={avatarUrl}
                      setImage={setAvatarUrl}
                      isEditing={isEditing}
                      validate
                      onImageError={(e) => {
                        e.target.src = DEFAULT_IMAGE;
                      }}
                      minHeight={160}
                    />
                  </div>
                </Form.Item>
                <div className="account-fullname">
                  {detailForm.getFieldValue("full_name")}
                </div>
                <div className="account-status">
                  <div className="tag-wrapper">
                    <div
                      className={`tag tag-${dataDetail?.status?.toLocaleLowerCase()}`}
                    >
                      {getTextTag[dataDetail?.status?.toLocaleLowerCase()]}
                    </div>
                  </div>
                </div>
                {dataDetail?.status === "suspend" &&
                  dataDetail?.deactivate_note && (
                    <div className="account-reason">
                      <div className="account-reason-title">
                        Lý do:
                        <span className="account-reason-content">
                          {dataDetail?.deactivate_note}
                        </span>
                      </div>
                    </div>
                  )}
                <Collapse
                  defaultActiveKey={["1", "2", "3"]}
                  bordered={false}
                  expandIcon={({ isActive }) => (
                    <CaretRightOutlined rotate={isActive ? 90 : 0} />
                  )}
                >
                  <Collapse.Panel header="Thông tin tổng quan" key="1">
                    <div className="form-divide">
                      <div className="child-form">
                        {/* <Form.Item
                            label="Họ và tên" name="full_name" rules={[
                              {
                                required: true,
                                message: 'Vui lòng nhập họ tên',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item> */}
                        <Form.Item label="ID" name="account_id">
                          <Input disabled />
                        </Form.Item>
                      </div>
                      <div className="child-form">
                        <Form.Item
                          label="Tên tài khoản"
                          name="username"
                          rules={[
                            {
                              required: true,
                              message: "Vui lòng nhập tên tài khoản",
                            },
                          ]}
                        >
                          <Input disabled />
                        </Form.Item>
                      </div>
                    </div>

                    <div className="form-divide">
                      <div className="child-form">
                        <Form.Item
                          label="Email"
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: "Vui lòng nhập địa chỉ email",
                            },
                            {
                              type: "email",
                              message: "Địa chỉ email không hợp lệ",
                            },
                          ]}
                        >
                          <Input disabled={!isEditing} />
                        </Form.Item>
                      </div>
                      <div className="child-form">
                        <Form.Item
                          label="Số điện thoại"
                          name="phone_number"
                          rules={[
                            {
                              required: true,
                              message: "Vui lòng nhập số điện thoại",
                            },
                            {
                              pattern: regexPhoneNumber,
                              message: "Vui lòng nhập đúng số điện thoại!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </div>
                    </div>

                    <div className="form-divide">
                      <div className="child-form">
                        <Form.Item
                          label="Giới tính"
                          name="gender"
                          rules={[
                            {
                              required: true,
                              message: "Vui lòng chọn giới tính",
                            },
                          ]}
                        >
                          <Select size="large">
                            <Select.Option value="male">Nam</Select.Option>
                            <Select.Option value="female">Nữ</Select.Option>
                          </Select>
                        </Form.Item>
                      </div>
                      <div className="child-form">
                        <Form.Item
                          label="Số CMND/CCCD"
                          name="identity_card"
                          rules={[
                            {
                              required: true,
                              message: "Vui lòng nhập Số CMND/CCCD",
                            },
                            {
                              min: 9,
                              message: "Vui lòng nhập đủ Số CMND/CCCD",
                            },
                            {
                              max: 12,
                              message: "Vui lòng nhập đủ Số CMND/CCCD",
                            },
                            {
                              pattern: /^[0-9]*$/,
                              message:
                                "Vui lòng nhập đúng định dạng Số CMND/CCCD",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </div>
                    </div>

                    <Form.Item
                      label="Địa chỉ liên hệ"
                      name="address"
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng nhập địa chỉ",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <div className="form-divide">
                      <div className="child-form">
                        <Form.Item
                          label="Ngày gia nhập công ty"
                          name="joined_at"
                        >
                          <DatePicker format="DD/MM/YYYY" size="large" />
                        </Form.Item>
                      </div>
                      <div className="child-form">
                        <Form.Item
                          label="Trình độ học vấn"
                          name="academic_level"
                        >
                          <Select size="large">
                            {ACADEMIC_LEVEL.map((level) => (
                              <Select.Option key={level.id} value={level.value}>
                                {level.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>

                    <div className="form-divide">
                      <div className="child-form">
                        <Form.Item
                          label="Phòng ban làm việc"
                          name="deparment"
                          rules={[
                            {
                              required: true,
                              message: "Vui lòng chọn phòng ban",
                            },
                          ]}
                        >
                          <Select
                            disabled={!isEditing}
                            size="large"
                            onChange={(value) => {
                              setSelectedDepartment(value);
                              detailForm.setFieldValue("role_id", null);
                              setRoleList([]);
                            }}
                          >
                            {departmentList.map((deparment) => (
                              <Select.Option
                                key={deparment.id}
                                value={deparment.id}
                              >
                                {deparment.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>

                      <div className="child-form">
                        <Form.Item
                          label="Chức vụ"
                          name="role_id"
                          rules={[
                            {
                              required: true,
                              message: "Vui lòng chọn chức vụ",
                            },
                          ]}
                        >
                          <Select
                            size="large"
                            disabled={!isEditing}
                            onChange={handleChangeRole}
                          >
                            {roleList.map((role) => (
                              <Select.Option key={role.id} value={role.id}>
                                {role.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>

                    {isTechnicalAccount && (
                      <div className="form-divide">
                        <div className="child-form">
                          <Form.Item
                            label="Nhà mạng phụ trách"
                            name="provider_ids"
                          >
                            <Select
                              mode="multiple"
                              placeholder="Chọn nhà mạng phụ trách"
                            >
                              {(providerList || []).map((item) => (
                                <Select.Option key={item.id} value={item.id}>
                                  {item.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>

                        <div className="child-form" />
                      </div>
                    )}

                    <Form.Item label="Ghi chú" name="note">
                      <Input.TextArea rows={2} />
                    </Form.Item>
                  </Collapse.Panel>
                  <Collapse.Panel header="Thông tin thanh toán" key="2">
                    <div className="form-divide">
                      <div className="child-form">
                        <Form.Item label="Ngân hàng" name="bank">
                          <Input />
                        </Form.Item>
                      </div>
                      <div className="child-form">
                        <Form.Item label="Chi nhánh" name="branch_name">
                          <Input />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="form-divide">
                      <div className="child-form">
                        <Form.Item label="Chủ tài khoản" name="account_owner">
                          <Input />
                        </Form.Item>
                      </div>
                      <div className="child-form">
                        <Form.Item label="Số tài khoản" name="account_number">
                          <Input />
                        </Form.Item>
                      </div>
                    </div>
                  </Collapse.Panel>

                  <Collapse.Panel header="Thông tin khác" key="3">
                    <div className="form-divide">
                      <div className="child-form">
                        <Form.Item
                          label="Người tạo tài khoản"
                          name="creator_full_name"
                        >
                          <Input disabled />
                        </Form.Item>
                      </div>
                      <div className="child-form">
                        <Form.Item label="Ngày tạo tài khoản" name="created_at">
                          <DatePicker
                            format="DD/MM/YYYY"
                            disabled
                            size="large"
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </Collapse.Panel>
                </Collapse>
              </Form>
            </Tabs.TabPane>
          </Tabs>
        )}
      </Drawer>

      <Modal
        centered
        open={isOpenConfirmModal}
        onCancel={handleCloseModalConfirm}
        onOk={handleChangeStatus}
        wrapClassName={`modal-activate-sim ${
          dataDetail?.status === "suspend" ? "modal-approve" : "modal-reject"
        }`}
        okText="Xác nhận"
        cancelText="Hủy"
        style={{ width: 456 }}
      >
        <div className="modal-content">
          {dataDetail?.status === "suspend" ? (
            <div>
              <div className="modal-title">Bạn muốn kích hoạt tài khoản?</div>
              <div className="modal-description">
                Tài khoàn này sẽ được kích hoạt sau khi bạn xác nhận
              </div>
            </div>
          ) : (
            <div className="reject-info">
              <div className="modal-title">Bạn muốn vô hiệu hoá tài khoản?</div>
              <div className="modal-description">
                Tài khoàn này sẽ bị vô hiệu hoá sau khi bạn xác nhận
              </div>
              <TextArea
                rows={2}
                placeholder="Nhập lý do từ chối"
                style={{ marginBottom: "16px" }}
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </div>
          )}
        </div>
      </Modal>

      <Modal
        centered
        open={isOpenModalDelete}
        onCancel={() => setIsOpenModalDelete(false)}
        onOk={handleDeleteAccount}
        wrapClassName="modal-activate-sim modal-reject"
        okText="Xác nhận"
        cancelText="Hủy"
        style={{ width: 456 }}
      >
        <div className="modal-content">
          <div className="reject-info">
            <div className="modal-title">Xác nhận xoá tài khoản tài khoản?</div>
            <div className="modal-description">
              Tài khoản này sẽ không còn tồn tại trong hệ thống. Vui lòng xác
              nhận
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={isOpenConfirmModalResetPassword}
        title={
          <div
            style={{ fontSize: 20 }}
            dangerouslySetInnerHTML={{
              __html: TEXT.CONFIRM_RESET_PASSWORD_TITLE,
            }}
          />
        }
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Hủy
          </Button>,
          <Button
            key="submit"
            className="btn-confirm"
            onClick={handleSubmit}
            disabled={isResetPassword}
          >
            Xác nhận
          </Button>,
        ]}
        width={456}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: TEXT.CONFIRM_RESET_PASSWORD_CONTENT,
          }}
        />
      </Modal>
    </>
  );
}

export default DetailAccountDrawer;
