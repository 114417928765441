import { DatePicker, Drawer, InputNumber, message, Table } from "antd";
import { Redirect, Route, Switch } from "react-router-dom";
import { useEffect, useState } from "react";
import { CloseCircleFilled } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import Loadable from "react-loadable";
import { onMessage } from "firebase/messaging";
import Header from "../../components/defaultLayout/header";
import SideBar from "../../components/defaultLayout/sideBar";
import { getProfile } from "../../apis/authenticationApis";
import { get, KEYS, set } from "../../util/localStorage";
import { isMobileDevice } from "../../util";
import {
  handlePermission,
  setPermission,
} from "../../redux/reducers/permission";
import routes from "../../constants/routes";
import { messaging, getToken } from "../../firebase/firebaseHelper";
import { VALID_API_KEY } from "../../constants/firebase";
import NotificationApis from "../../apis/notificationApis";
import { showNotification } from "../../firebase/notificationHelper";

function Loading() {
  return <div>Loading...</div>;
}
Table.defaultProps = {
  locale: {
    emptyText: "Không có dữ liệu",
  },
};
DatePicker.defaultProps = {
  placeholder: "",
  style: { width: "100%" },
};
InputNumber.defaultProps = {
  style: { width: "100%" },
};

Drawer.defaultProps = {
  closeIcon: <CloseCircleFilled style={{ fontSize: "24px" }} />,
};

message.config({
  maxCount: 1,
});

const PartnerAccountManagePage = Loadable({
  loader: () => import("../accountManagePage/partner"),
  loading: Loading,
});

const DefaultLayout = () => {
  const [token, setToken] = useState(get(KEYS.TOKEN));
  const [isSignedIn, setIsSignedIn] = useState(get(KEYS.TOKEN));
  const [user, setUser] = useState(null);
  const [isShowSideBar, setIsShowSideBar] = useState(window.innerWidth > 1023);
  const [isLoadingRole, setLoadingRole] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    async function initUser() {
      try {
        setLoadingRole(true);
        const res = await getProfile();
        setUser(res?.data?.data);
        set(
          KEYS.MY_ROLES,
          (res?.data?.data?.roles || []).map((item) => item.role_tag)
        );
        set(KEYS.USER, res?.data?.data);

        // mapping permission role
        dispatch(
          setPermission(
            handlePermission(
              (res?.data?.data?.roles || []).map((item) => item.role_tag)
            )
          )
        );
      } catch (err) {
        setLoadingRole(false);
      } finally {
        setLoadingRole(false);
      }
    }

    if (token) {
      initUser();
    }
  }, []);

  const userPermission = handlePermission(get(KEYS.MY_ROLES));
  if (userPermission?.[0]?.role === "partner_representative") {
    routes.push({
      path: "/quan-ly-tai-khoan",
      name: "Partner Account Manage Page",
      component: PartnerAccountManagePage,
    });
  }

  const requestPermission = async () => {
    try {
      const deviceToken = await getToken(messaging, {
        vapidKey: VALID_API_KEY,
      });
      if (deviceToken) {
        const currentToken = get(KEYS.FIREBASE_REGISTER_TOKEN);
        if (deviceToken !== currentToken) {
          await NotificationApis.addRegisterToken({
            token: deviceToken,
            device_type: "web",
          });
          set(KEYS.FIREBASE_REGISTER_TOKEN, deviceToken);
        }
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    } catch (err) {
      console.error("An error occurred while retrieving token. ", err);
      message.error(
        "Đăng ký thông báo thất bại, Vui lòng chấp nhận thông báo và đăng nhập lại!"
      );
    }
  };

  const isValidRole = () => {
    const myRole = (user?.roles || []).map((item) => item.role_tag) || [];
    return true;
  };

  const listenForNotifications = (value) => {
    onMessage(value, (payload) => {
      console.log("Message received: ", payload);
      const { title, body } = payload.notification;
      showNotification(title, body);
    });
  };

  const handleFirebaseMessage = async () => {
    if (!isValidRole()) {
      return;
    }
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then((registration) => {
          console.log(
            "Service Worker registered with scope:",
            registration.scope
          );
        })
        .catch((err) => {
          console.error("Service Worker registration failed:", err);
        });
    }
    requestPermission();
    // listenForNotifications(messaging)
  };

  // useEffect(() => {
  //   // Request permission to send notifications
  //   if (!("Notification" in window)) {
  //     console.log("This browser does not support desktop notification");
  //   } else if (Notification.permission === "granted") {
  //     handleFirebaseMessage();
  //   } else {
  //     Notification.requestPermission().then((permission) => {
  //       if (permission === "granted") {
  //         handleFirebaseMessage();
  //       }
  //     });
  //   }
  // }, [user]);

  return !isSignedIn ? (
    <Redirect to="/login" />
  ) : (
    <div className="layout">
      <SideBar
        isShowSideBar={isShowSideBar}
        setIsShowSideBar={setIsShowSideBar}
        isLoadingRole={isLoadingRole}
      />
      <div
        className="right-panel"
        style={{
          marginLeft: isMobileDevice() ? "0" : "300px",
        }}
      >
        <Header user={user} setIsShowSidebar={setIsShowSideBar} />
        {isSignedIn && (
          <div className="main">
            <Switch>
              {routes.map((route, idx) =>
                route.component ? (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={(props) => <route.component {...props} />}
                  />
                ) : null
              )}
            </Switch>
          </div>
        )}
      </div>
    </div>
  );
};
export default DefaultLayout;
